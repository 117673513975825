import React, { useEffect, useState } from 'react'
import { StreamChat } from 'stream-chat'
import { Chat, Channel, ChannelList } from 'stream-chat-react'
import { useChecklist } from './ChecklistTasks'
import { EmojiIcon, LightningBoltSmall, SendIcon } from './assets'

import 'stream-chat-react/dist/css/index.css'
import './chat.css'

import {
  CreateChannel,
  CustomMessage,
  MessagingChannelList,
  MessagingChannelPreview,
  MessagingInput,
  MessagingThreadHeader,
} from './components'

import { getRandomImage } from './assets'
import { ChannelInner } from './components/ChannelInner/ChannelInner'

import { ChatProvider } from '@app/hooks/use-chat'
import { useChat } from '@app/hooks/use-chat'
import { useAuth } from '@app/hooks/use-auth'

const urlParams = new URLSearchParams(window.location.search)

// const apiKey = urlParams.get('apikey') || process.env.REACT_APP_STREAM_KEY;
// const user = urlParams.get('user') || process.env.REACT_APP_USER_ID;
// const userToken = urlParams.get('user_token') || process.env.REACT_APP_USER_TOKEN;
// const targetOrigin = urlParams.get('target_origin') || process.env.REACT_APP_TARGET_ORIGIN;

// const apiKey = 'gacrrdd2aupm';
// const user = 'brand-Ana__M-57';
// const userToken = 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VyX2lkIjoiYnJhbmQtQW5hX19NLTU3In0.UpxqTo3IsffojLYyn37v1u5jwtS6Uq-Jw5YJ5pPfY7M';
const targetOrigin = 'http://localhost:3000'
const noChannelNameFilter = true
const skipNameImageSet = urlParams.get('skip_name_image_set') || false

// const userToConnect = { id: user, name: user, image: getRandomImage() };

// if (skipNameImageSet) {
//   delete userToConnect.name;
//   delete userToConnect.image;
// }

export const GiphyContext = React.createContext({})

const App = () => {
  const chat = useChat()
  const auth = useAuth()

  const [chatClient, setChatClient] = useState(null)
  const [giphyState, setGiphyState] = useState(false)
  const [isCreating, setIsCreating] = useState(false)
  const [isMobileNavVisible, setMobileNav] = useState(false)
  const [theme, setTheme] = useState('light')

  useChecklist(chatClient, targetOrigin)

  useEffect(() => {
    if (!chat.clientReady) {
      console.log('chatClient not ready')
    }

    setChatClient(chat.chatClient)

    // const initChat = async () => {
    //   const client = StreamChat.getInstance(apiKey, {
    //     enableInsights: true,
    //     enableWSFallback: true,
    //   });
    //   await client.connectUser(userToConnect, userToken);
    //   setChatClient(client);
    // };

    // initChat();

    return () => chatClient?.disconnectUser()
  }, [chat.clientReady]) // eslint-disable-line

  useEffect(() => {
    const handleThemeChange = ({ data, origin }) => {
      // handle events only from trusted origin
      if (origin === targetOrigin) {
        if (data === 'light' || data === 'dark') {
          setTheme(data)
        }
      }
    }

    window.addEventListener('message', handleThemeChange)
    return () => window.removeEventListener('message', handleThemeChange)
  }, [])

  useEffect(() => {
    const mobileChannelList = document.querySelector('#mobile-channel-list')
    if (isMobileNavVisible && mobileChannelList) {
      mobileChannelList.classList.add('show')
      document.body.style.overflow = 'hidden'
    } else if (!isMobileNavVisible && mobileChannelList) {
      mobileChannelList.classList.remove('show')
      document.body.style.overflow = 'auto'
    }
  }, [isMobileNavVisible])

  useEffect(() => {
    /*
     * Get the actual rendered window height to set the container size properly.
     * In some browsers (like Safari) the nav bar can override the app.
     */
    const setAppHeight = () => {
      const doc = document.documentElement
      doc.style.setProperty('--app-height', `${window.innerHeight}px`)
    }

    setAppHeight()

    window.addEventListener('resize', setAppHeight)
    return () => window.removeEventListener('resize', setAppHeight)
  }, [])

  const toggleMobile = () => setMobileNav(!isMobileNavVisible)

  const giphyContextValue = { giphyState, setGiphyState }

  if (!chatClient) return null
  console.log('client--------->', auth?.user?.chatUserId)

  // const filters = noChannelNameFilter
  // ? { type: 'messaging', members: { $in: [auth?.userInfo?.chat_user_id] } }
  // : { type: 'messaging', name: 'Social Demo', demo: 'social' };

  const filters = { type: 'messaging', members: { $in: [`${auth?.user?.chatUserId}`] } }

  const options = { state: true, watch: true, presence: true, limit: 8 }

  const sort = {
    last_message_at: -1,
    updated_at: -1,
    cid: 1,
  }

  return (
    <div className="chat-block">
      <Chat client={chatClient}>
        <div id="mobile-channel-list" onClick={toggleMobile} className="show">
          <ChannelList
            filters={filters}
            sort={sort}
            options={options}
            List={(props) => <MessagingChannelList {...props} onCreateChannel={() => setIsCreating(!isCreating)} />}
            Preview={(props) => <MessagingChannelPreview {...props} {...{ setIsCreating }} />}
          />
        </div>
        <div>
          <Channel
            // Input={MessagingInput}
            maxNumberOfFiles={10}
            Message={CustomMessage}
            multipleUploads={true}
            ThreadHeader={MessagingThreadHeader}
            TypingIndicator={() => null}>
            {isCreating && <CreateChannel toggleMobile={toggleMobile} onClose={() => setIsCreating(false)} />}
            <GiphyContext.Provider value={giphyContextValue}>
              <ChannelInner theme={theme} toggleMobile={toggleMobile} />
            </GiphyContext.Provider>
          </Channel>
        </div>
      </Chat>
    </div>
  )
}

export default () => (
  <ChatProvider>
    {' '}
    <App />{' '}
  </ChatProvider>
)

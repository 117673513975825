import _ from 'lodash'
import React, { useState } from 'react'
import { Text, View, Image, StyleSheet, SafeAreaView, ScrollView } from 'react-native'
import { ActivityIndicator, Surface } from 'react-native-paper'
import { useNavigation, useRoute } from '@react-navigation/core'
import { useQuery } from 'react-query'
import api from '@app/services/api.service'
import { useTranslation } from 'react-i18next'
import Colors from '@app/atoms/colors'
import { scale } from '@app/atoms/scale-view'
import { BASE_URL } from '@env'
import ValidCardOne from '@app/assets/images/validcard1.svg'
import ValidCardTwo from '@app/assets/images/validcard2.svg'
import ValidCardThree from '@app/assets/images/validcard3.svg'
import ValidCardFour from '@app/assets/images/validcard4.svg'

function ValidCardsScreen() {
  const route = useRoute()
  const navigation = useNavigation()
  const { t, i18n } = useTranslation()
  const localId = i18n.language

  // const validcards = useQuery('assets', () => { //${BASE_URL}/${localId}/assets.json`
  //     return fetch(`https://development.vermutapp.com//${localId}/assets.json`)
  //         .then((res) => res.json())
  //         .catch((err) => console.log('erorr-------->', err))
  // })
  const validcards = useQuery(['assets'], api.getValidCards)

  if (validcards.isLoading) {
    return (
      <SafeAreaView style={{ flex: 1, justifyContent: 'center', alignItems: 'center' }}>
        <ActivityIndicator animating={true} />
      </SafeAreaView>
    )
  }

  const cards = validcards.data || []
  // [
  //     {
  //         "name": "Suscripción 10€",
  //         "text": "Social Familia Vermut",
  //         "imageUri": "/en/images/validcard1.png"

  //     },
  //     {
  //         "name": "Suscripción 60€",
  //         "text": "VIP Total",
  //         "imageUri": "/en/images/validcard2.png"
  //     },
  //     {
  //         "name": "Embajadores",
  //         "text": "",
  //         "imageUri": "/en/images/validcard3.png"
  //     },
  //     {
  //         "name": "Líderes",
  //         "text": "",
  //         "imageUri": "/en/images/validcard4.png"
  //     }

  // ]
  return (
    <SafeAreaView style={{ flex: 1, backgroundColor: Colors.BACKGROUND_COLORS.C10 }}>
      <ScrollView>
        <Text style={styles.name}>Tarjetas válidas</Text>
        <View style={{ flex: 1, flexDirection: 'row', justifyContent: 'space-evenly' }}>
          <View>
            <View style={{ width: scale(154), height: scale(102) }}>
              {/* <Image source={{ uri: BASE_URL + cards[0]?.imageUri }} width={scale(154)} height={scale(102)} /> */}
              <Image source={ValidCardTwo} width={scale(154)} height={scale(102)} />
            </View>
            <Text style={styles.imageTitle}>{cards[0]?.name}</Text>
            <Text style={styles.imageSubTitle}>{cards[0]?.text}</Text>
          </View>
          <View>
            <View style={{ width: scale(154), height: scale(102) }}>
              {/* <Image source={{ uri: BASE_URL + cards[1]?.imageUri }} width={scale(154)} height={scale(102)} /> */}
              <Image source={ValidCardOne} width={scale(154)} height={scale(102)} />
            </View>
            <Text style={styles.imageTitle}>{cards[1]?.name}</Text>
            <Text style={styles.imageSubTitle}>{cards[0]?.text}</Text>
          </View>
        </View>
        <View style={{ flex: 1, flexDirection: 'row', justifyContent: 'space-evenly', marginTop: scale(30) }}>
          <View>
            <View style={{ width: scale(154), height: scale(102) }}>
              {/* <Image source={{ uri: BASE_URL + cards[2]?.imageUri }} width={scale(154)} height={scale(102)} /> */}
              <Image source={ValidCardThree} width={scale(154)} height={scale(102)} />
            </View>
            <Text style={styles.imageTitle}>{cards[2]?.name}</Text>
            <Text style={styles.imageSubTitle}>{cards[2]?.text}</Text>
          </View>
          <View>
            <View style={{ width: scale(154), height: scale(102) }}>
              {/* <Image source={{ uri: BASE_URL + cards[3]?.imageUri }} width={scale(154)} height={scale(102)} /> */}
              <Image source={ValidCardFour} width={scale(154)} height={scale(102)} />
            </View>
            <Text style={styles.imageTitle}>{cards[3]?.name}</Text>
            <Text style={styles.imageSubTitle}>{cards[3]?.text}</Text>
          </View>
        </View>
      </ScrollView>
    </SafeAreaView>
  )
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },

  name: {
    fontFamily: 'Roboto',
    fontSize: scale(24),
    color: Colors.TEXT_COLORS.C800,
    textAlign: 'left',
    fontWeight: '500',
    lineHeight: scale(24.77),
    marginTop: scale(20),
    marginBottom: scale(20),
    marginLeft: scale(10),
  },
  imageTitle: {
    fontFamily: 'Roboto',
    fontSize: scale(16),
    color: Colors.TEXT_COLORS.C800,
    textAlign: 'center',
    fontWeight: '500',
    lineHeight: scale(16.43),
    marginTop: scale(10),
  },
  imageSubTitle: {
    fontFamily: 'Roboto',
    fontSize: scale(14),
    color: Colors.TEXT_COLORS.C800,
    textAlign: 'center',
    fontWeight: '400',
    lineHeight: scale(16.41),
  },
})
export default ValidCardsScreen

import _ from 'lodash'
import React, { useState } from 'react'
import dayjs from 'dayjs'
import { Text, View, Image, StyleSheet, SafeAreaView, ScrollView, FlatList, RefreshControl } from 'react-native'
import { useNavigation, useRoute } from '@react-navigation/core'
import { useMutation, useQuery, useInfiniteQuery } from 'react-query'
import api from '@app/services/api.service'
import { useTranslation } from 'react-i18next'
import { Avatar } from 'react-native-paper'
import Colors from '@app/atoms/colors'
import { scale } from '@app/atoms/scale-view'
import Divider from '@app/atoms/divider.atom'

function CommentListScreen(props) {
  const { id, commentTimestamp } = props
  const route = useRoute()
  const navigation = useNavigation()
  const { t } = useTranslation()

  React.useEffect(() => {
    comments.refetch()
  }, [commentTimestamp])

  let comments = useInfiniteQuery(['getSessionComments', id], api.getSessionComments, {
    getNextPageParam: (lestestPage, pages) => {
      const { currentPage, lastPage } = lestestPage.meta
      const nextPage = currentPage + 1
      return nextPage <= lastPage ? nextPage : undefined
    },
  })
  const handleLoadMore = ({ distanceFromEnd }) => {
    if (!onEndReachedCalledDuringMomentum.current) {
      comments.fetchNextPage()
      onEndReachedCalledDuringMomentum.current = true
    }
  }
  let paginatedActivityList = []
  if (comments.data?.pages) {
    for (let p of comments.data.pages) {
      paginatedActivityList = paginatedActivityList.concat(p.data)
    }
  }

  return (
    <SafeAreaView style={{ flex: 1, backgroundColor: Colors.BACKGROUND_COLORS.C10, marginBottom: scale(30) }}>
      <View>
        <Text style={styles.name}>Comentarios</Text>
        <FlatList
          refreshing={comments.isLoading}
          refreshControl={<RefreshControl refreshing={comments.isLoading} onRefresh={comments.refetch} />}
          data={paginatedActivityList}
          keyExtractor={(item, index) => `activity-list-inside-${item.id}`}
          onEndReached={() => handleLoadMore}
          onEndReachedThreshold={0.5}
          onMomentumScrollBegin={() => {
            onEndReachedCalledDuringMomentum.current = false
          }}
          initialNumToRender={5}
          // refreshing={activityList.isLoading}
          // onRefresh={() => activityList.refetch()}
          renderItem={({ item, index }) => {
            const profileImage = item?.partner?.photo_url
              ? item?.partner?.photo_url
              : 'https://www.vermutapp.com/assets/front/images/logos/icon-192x192.png'
            const commentDate = dayjs(item.createdAt).format('DD/MM/YYYY')
            const commentTime = dayjs(item.createdAt).format('h:mm')
            return (
              <View style={{ paddingHorizontal: scale(20), marginTop: scale(5) }} key={`comment-${item.id}`}>
                <View style={{ flex: 1, flexDirection: 'row', marginLeft: scale(20), justifyContent: 'center' }}>
                  <Avatar.Image size={scale(48)} source={{ uri: profileImage }} />
                  <View style={{ flex: 1, flexDirection: 'column', marginLeft: scale(20) }}>
                    <View style={{ flex: 1, flexDirection: 'row' }}>
                      <Text style={styles.dateStyle}>{commentDate}</Text>
                      <Text style={[styles.dateStyle, { marginLeft: scale(23) }]}>{commentTime}</Text>
                    </View>
                    <Text style={styles.commentText}>{item.comment}</Text>
                  </View>
                </View>
              </View>
            )
          }}
          ItemSeparatorComponent={() => {
            return (
              <Divider height={scale(1)} color={Colors.TEXT_COLORS.C300} containerStyle={{ marginBottom: scale(10) }} />
            )
          }}
        />
      </View>
    </SafeAreaView>
  )
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },

  name: {
    fontFamily: 'Roboto',
    fontSize: scale(24),
    color: Colors.TEXT_COLORS.C800,
    textAlign: 'left',
    fontWeight: '500',
    lineHeight: scale(24.77),
    marginTop: scale(20),
    marginBottom: scale(20),
    marginLeft: scale(20),
  },
  dateStyle: {
    fontFamily: 'Roboto',
    fontSize: scale(16),
    color: Colors.TEXT_COLORS.C800,
    fontWeight: '500',
    lineHeight: scale(16.43),
    marginTop: scale(10),
  },
  commentText: {
    fontFamily: 'Roboto',
    fontSize: scale(18),
    color: Colors.TEXT_COLORS.C800,
    fontWeight: '400',
    lineHeight: scale(21.09),
  },
})
export default CommentListScreen

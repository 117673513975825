import React, { useState } from 'react'
import PropTypes from 'prop-types'
import {
  View,
  Text,
  Image,
  StyleSheet,
  ImageBackground,
  Pressable,
  Dimensions,
  SafeAreaView,
  Modal,
  TouchableWithoutFeedback,
  Animated,
  TouchableOpacity,
  Linking,
} from 'react-native'
import { useMutation } from 'react-query'
import { Entypo } from '@expo/vector-icons'
import routes from '@app/navigation/routes'
import { useTranslation, Translation } from 'react-i18next'
import { TabActions, useNavigation } from '@react-navigation/native'
import { CollapsibleHeaderTabView } from 'react-native-tab-view-collapsible-header'
import globalStyles from '@app/atoms/global-style.atom'
import HeadSection from '@app/molecules/head-section.molecule'
import gs from '@app/atoms/gs.atom'
import api from '@app/services/api.service'
import Colors from '@app/atoms/colors'
import * as utils from '@app/utils'
import { fScale, scale } from '@app/atoms/scale-view'
import { HScrollView } from 'react-native-head-tab-view'
import Icons from '@app/atoms/icons.atom'
import { AntDesign } from '@expo/vector-icons'
// import { isAndroid } from '@app/helpers'
import Accordion from 'react-native-collapsible/Accordion'
import Divider from '@app/atoms/divider.atom'
import PhoneContact from '@app/molecules/phone-contact.molecule'
import SecondaryButton from '@app/atoms/support-button.atom'

import './image.css'

const ScreenWidth = Dimensions.get('screen').width
const errorImage = require('@app/assets/images/no-image.png')
const initialLayout = { width: Dimensions.get('window').width }

const ShowAttendanceModal = ({ showAttendanceConfirmation, bookings, setShowAttendanceConfirmation, setAttendance, setTimestamp }) => {
  const { t } = useTranslation()
  const attendanceMuation = useMutation(api.attendance, {
    onSuccess: async (data, variables, context) => {
      setAttendance(true)
      setTimestamp(Date.now())
    },
    onError: async (errors, variables, context) => {
      console.log({ errors })
    },
  })
  let data = bookings.filter(function (o1) {
    return o1.id == showAttendanceConfirmation
  })
  return (
    <>
      <SafeAreaView style={{ flex: 1, backgroundColor: Colors.BACKGROUND_COLORS.C50 }}>
        <Modal
          animationType="slide"
          transparent={true}
          visible={showAttendanceConfirmation != 0 ? true : false}
          onRequestClose={() => { }}>
          <View style={modelStyles.centeredView}>
            <View style={modelStyles.modalView}>
              <Text style={modelStyles.canelText}>{t('Are you sure, do you want to continue?')}</Text>
              {/* <View style={[styles.nameContainer]}>
                <Text style={styles.cancelName}>
                  {data[0]?.user?.firstname} {data[0]?.user?.lastname}
                </Text>
              </View> */}
              <View style={{ flexDirection: 'row', marginTop: scale(20) }}>
                <Pressable
                  style={[modelStyles.button, modelStyles.buttonNo]}
                  onPress={() => setShowAttendanceConfirmation(false)}>
                  <Text style={modelStyles.buttonNoText}>{t(`No`)}</Text>
                </Pressable>
                <Pressable
                  style={[modelStyles.button, modelStyles.buttonYes]}
                  onPress={() => {
                    setShowAttendanceConfirmation(false)
                    data[0]?.attendenceStatus === 'Will Attend'
                      ? attendanceMuation.mutate({ id: data[0].id, attendence_status: 'Attended' })
                      : attendanceMuation.mutate({ id: data[0].id, attendence_status: 'Will Attend' })
                  }}>
                  <Text style={modelStyles.buttonYesText}>{t(`Yes`)}</Text>
                </Pressable>
              </View>
            </View>
          </View>
        </Modal>
      </SafeAreaView>
    </>
  )
}

function ActivityFullWidthCard({ isSearchStack = false, isGuest = false, containerStyle, bookings, setTimestamp }) {
  const { t } = useTranslation()
  const navigation = useNavigation()
  const [showCancelModal, setCancelModal] = useState(false)
  const [showConfirmModal, setConfirmModal] = useState(false)
  const [showAttendance, setAttendance] = useState(false)
  const [showCallModal, setCallModal] = useState(false)
  const [showAttendanceConfirmation, setShowAttendanceConfirmation] = useState(0)
  const [index, setIndex] = React.useState(0)

  let opacityStyle = { opacity: 1 }
  let backgroudStyle = { backgroundColor: '#fff' }
  let shadow = gs.shadowStyleMain
  const [activeSections, setActiveSections] = React.useState([])




  const cancelBookingMuation = useMutation(api.cancelBooking, {
    onSuccess: async (data, variables, context) => {
      setConfirmModal(true)
      setTimestamp(Date.now())
    },
    onError: async (errors, variables, context) => {
      console.log({ errors })
    },
  })
  return (
    <>
      <Pressable onPress={() => { }}>
        <Accordion
          sectionContainerStyle={{}}
          underlayColor={Colors.BACKGROUND_COLORS.C50}
          activeSections={activeSections}
          sections={bookings}
          renderHeader={(content, index, isActive) => {            
            let cost = parseInt(content?.totalCost)
            cost = cost / 100
            return (
              <View
                style={
                  isActive
                    ? [containerStyle, styles.container, shadow, backgroudStyle, { minHeight: scale(180) }]
                    : [containerStyle, styles.container, shadow, backgroudStyle, { minHeight: scale(83) }]
                }>
                <View style={styles.activityInfoContainer}>
                  {/* <View class="arrow" style={{ marginRight: scale(10) }}> */}
                  {isActive ? (
                    <Icons.UpArrow />
                  ) : (
                    <Icons.DownArrow />
                  )}
                  {/* </View> */}
                  <View style={[styles.nameContainer]}>
                    <Text>
                      {content?.user?.firstname} {content?.user?.lastname} {content?.totalParticipants == 1 ? '' : `+  ${content?.totalParticipants-1}`} 
                    </Text>
                    <Text style={{ marginTop: scale(4) }}>
                      {t(content?.paymentMethod)} {cost}€
                    </Text>
                  </View>
                  <View class="check" style={{ width: scale(28), height: scale(28) }}>
                    <TouchableOpacity
                      onPress={() => {
                        setShowAttendanceConfirmation(content?.id)
                      }}
                      style={{ marginBottom: scale(5), alignItems: 'flex-end' }}>
                      {content?.attendenceStatus === 'Attended' ? (
                        <Icons.FilledCheck />
                      ) : (
                        <Icons.Check />
                      )}
                    </TouchableOpacity>
                  </View>
                </View>
                {isActive && (
                  <View style={[styles.detailsContainer, { marginTop: scale(-20) }]}>
                    <View style={[styles.subDetailsContainer]}>

                      <Pressable
                        onPress={() => {
                          setCallModal(true)
                        }}>
                        <View style={{ flex: 1, flexDirection: 'row', marginLeft: scale(30) }}>
                          <Icons.Phone />
                          <Text style={{ marginTop: scale(5) }}>{t('Contact')}</Text>
                        </View>
                      </Pressable>
                    </View>

                    {content?.attendenceStatus != 'Attended' && <>
                      <Divider
                        height={scale(1)}
                        color={Colors.TEXT_COLORS.C300}
                        containerStyle={{ marginBottom: scale(5) }}
                      />
                      <Pressable
                        onPress={() => {
                          setCancelModal(true)
                        }}>
                        <Text style={[styles.cancelText, { textAlign: 'center', padding: scale(10) }]}>
                          {t('Cancel booking')}
                        </Text>
                      </Pressable>
                    </>
                    }
                  </View>
                )}
                {showCancelModal && isActive && (
                  <SafeAreaView style={{ flex: 1, backgroundColor: Colors.BACKGROUND_COLORS.C50 }}>
                    <Modal animationType="slide" transparent={true} visible={showCancelModal} onRequestClose={() => { }}>
                      <View style={modelStyles.centeredView}>
                        {showCancelModal && (
                          <View style={modelStyles.modalView}>
                            <Text style={modelStyles.canelText}>{t('You want to cancel the reservation of:')}</Text>
                            <View style={[styles.nameContainer]}>
                              <Text style={styles.cancelName}>
                                {content?.user?.firstname} {content?.user?.lastname}
                              </Text>
                            </View>
                            <View style={{ flexDirection: 'row', marginTop: scale(20) }}>
                              <Pressable
                                style={[modelStyles.button, modelStyles.buttonNo]}
                                onPress={() => setCancelModal(false)}>
                                <Text style={modelStyles.buttonNoText}>{t(`No`)}</Text>
                              </Pressable>
                              <Pressable
                                style={[modelStyles.button, modelStyles.buttonYes]}
                                onPress={() => {
                                  setCancelModal(false)
                                  cancelBookingMuation.mutate({ id: content.id, cancellation_reason: 'test' })
                                }}>
                                <Text style={modelStyles.buttonYesText}>{t(`Yes`)}</Text>
                              </Pressable>
                            </View>
                          </View>
                        )}
                      </View>
                    </Modal>
                  </SafeAreaView>
                )}

                {showConfirmModal && isActive && (
                  <SafeAreaView style={{ flex: 1, backgroundColor: Colors.BACKGROUND_COLORS.C50 }}>
                    <Modal
                      animationType="slide"
                      transparent={true}
                      visible={showConfirmModal}
                      onRequestClose={() => { }}>
                      <View style={modelStyles.centeredView}>
                        {showConfirmModal && (
                          <View style={modelStyles.modalView}>
                            <View style={{ flex: 1, flexDirection: 'column' }}>
                              <TouchableOpacity
                                onPress={() => {
                                  setConfirmModal(false)
                                }}
                                style={{ marginBottom: scale(5), alignItems: 'flex-end' }}>
                                <AntDesign name="closecircle" size={29} color="#2A888C" />
                              </TouchableOpacity>
                              <Text style={modelStyles.confirmText}>
                                {t('The reservation has been canceled successfully.')}
                              </Text>
                            </View>
                          </View>
                        )}
                      </View>
                    </Modal>
                  </SafeAreaView>
                )}

                {showAttendanceConfirmation != 0 && (
                  < ShowAttendanceModal
                    showAttendanceConfirmation={showAttendanceConfirmation}
                    bookings={bookings}
                    setShowAttendanceConfirmation={setShowAttendanceConfirmation}
                    setAttendance={setAttendance}
                    setTimestamp={setTimestamp}
                  />
                )}
                {showAttendance && (
                  <SafeAreaView style={{ flex: 1, backgroundColor: Colors.BACKGROUND_COLORS.C50 }}>
                    <Modal
                      animationType="slide"
                      transparent={true}
                      visible={showAttendance}
                      onRequestClose={() => {
                      }}>
                      <View style={modelStyles.centeredView}>
                        {showAttendance && (
                          <View style={modelStyles.modalView}>
                            <View style={{ flex: 1, flexDirection: 'column', alignItems: 'center' }}>
                              <Icons.VermutsIcon />
                              <Text style={[modelStyles.confirmText, { fontSize: scale(24) }]}>
                                {t('It has been saved and sent successfully')}
                              </Text>
                              <View style={{ flexDirection: 'row', marginTop: scale(20) }}>
                                <Pressable
                                  style={[modelStyles.button, modelStyles.buttonYes]}
                                  onPress={() => setAttendance(false)}>
                                  <Text style={modelStyles.buttonYesText}>{t('It is understood!')}</Text>
                                </Pressable>
                              </View>
                            </View>
                          </View>
                        )}
                      </View>
                    </Modal>
                  </SafeAreaView>
                )}
                {showCallModal && isActive && (
                  <SafeAreaView style={{ flex: 1, backgroundColor: Colors.BACKGROUND_COLORS.C50 }}>
                    <Modal
                      animationType="slide"
                      transparent={true}
                      visible={showCallModal}
                      onRequestClose={() => {
                        setCallModal(false)
                      }}>
                      <View style={modelStyles.centeredView}>
                        <View style={modelStyles.modalView}>
                          <TouchableOpacity onPress={() => setCallModal(false)} style={{ alignItems: 'flex-end' }}>
                            <AntDesign name="closecircle" size={32} color="#A82137" />
                          </TouchableOpacity>
                          <Text
                            style={[
                              gs.subTitle400,
                              { color: Colors.TEXT_COLORS.C800, padding: scale(10), marginBottom: scale(22) },
                            ]}>
                            {t('Choose one of the two options')}
                          </Text>

                          <View style={{ flexDirection: 'row', justifyContent: 'space-evenly', marginTop: scale(10) }}>
                            <SecondaryButton
                              containerStyle={{}}
                              onPress={() => {
                                Linking.openURL(`tel:${content?.user?.phone}`)
                              }}
                              icon={<Icons.InputPhoneIcon width={scale(24)} height={scale(24)} strokeWidth="2.5" />}>
                              <Text style={[modelStyles.buttonText]}>{t('Contact')}</Text>
                            </SecondaryButton>
                            <SecondaryButton
                              containerStyle={{}}
                              onPress={() => {
                                Linking.openURL(`whatsapp://send?text=hello&phone=${content?.user?.phone}`)
                              }}
                              icon={<Icons.CommunityWhatsappIcon width={fScale(24)} height={fScale(24)} fill="#69AC6E" />}>
                              <Text style={[modelStyles.buttonText, { color: '#69AC6E' }]}>Whatsapp</Text>
                            </SecondaryButton>
                          </View>
                        </View>
                      </View>
                    </Modal>
                  </SafeAreaView>
                )}
              </View>
            )
          }}
          renderContent={(content) => {
            return <> </>
          }}
          onChange={setActiveSections}
          renderAsFlatList={false}
          expandMultiple={false}
        />
      </Pressable>
    </>
  )
}

ActivityFullWidthCard.propTypes = {
  activity: PropTypes.object.isRequired,
  // containerStyle: ViewPropTypes.style,
}

const styles = StyleSheet.create({
  container: {
    flex: 0,
    flexDirection: 'column',
    backgroundColor: '#fff',
    minHeight: scale(83),
    borderRadius: scale(20),
    marginBottom: scale(14.39),
    marginLeft: scale(16),
    marginRight: scale(16),
  },
  dateInfoContainer: {
    paddingHorizontal: scale(20),
    // paddingBottom: scale(10.19),
    justifyContent: 'center',
    alignItems: 'center',
    borderRightColor: Colors.TEXT_COLORS.C500,
    borderRightWidth: 1,
  },
  dateWeekName: {
    // minWidth: scale(106),
    textTransform: 'uppercase',
    fontFamily: 'Roboto',
    fontSize: scale(18),
    color: Colors.TEXT_COLORS.C800,
    textAlign: 'center',
    fontWeight: '500',
    lineHeight: scale(21.44),
  },
  dateNumber: {
    fontFamily: 'Roboto',
    fontSize: scale(54),
    color: Colors.TEXT_COLORS.C800,
    margin: scale(0),
    padding: scale(0),
    lineHeight: scale(63.28),
  },
  activityInfoContainer: {
    // paddingLeft: scale(15),
    // paddingRight: scale(10),
    // paddingTop: scale(12),
    padding: scale(20),
    justifyContent: 'start',
    flex: 1,
    flexDirection: 'row',
    // marginTop: scale(19),
  },
  activityInfoTiming: {
    ...gs.title500,
  },
  activityInfoPlace: {
    ...gs.textDetails400,
    marginTop: scale(11),
    marginBottom: scale(17),
    flexWrap: 'wrap-reverse',
  },
  knowMoreText: {
    ...gs.textDetails500,
    color: Colors.SECONDARY_COLORS.C400,
    alignSelf: 'flex-end',
    right: 0,
    bottom: scale(0),
  },
  activityStatusContainer: {
    // marginTop: scale(22),
  },
  nameContainer: {
    // justifyContent: 'space-between',
    flex: 1,
    flexDirection: 'column',
    // marginTop: scale(-10),
  },
  detailsContainer: {
    flex: 1,
    flexDirection: 'column',
    marginBottom: scale(20),
  },
  subDetailsContainer: {
    justifyContent: 'center', //'space-evenly',
    flex: 1,
    flexDirection: 'row',
    padding: scale(10),
  },
  cancelText: {
    // minWidth: scale(106),
    fontFamily: 'Roboto',
    fontSize: scale(18),
    color: Colors.PRIMARY_COLORS.C700,
    textAlign: 'center',
    fontWeight: '500',
    lineHeight: scale(21.44),
  },
})

const modelStyles = StyleSheet.create({
  centeredView: {
    flex: 1,
    justifyContent: 'center',
    backgroundColor: 'rgba(86, 86, 86, 0.9)',
  },
  modalView: {
    margin: scale(40),
    backgroundColor: '#FFFFFF',
    borderRadius: scale(30),
    paddingVertical: scale(25),
    paddingHorizontal: scale(20),
    shadowColor: '#000',
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.25,
    shadowRadius: 2,
    elevation: 2,
  },
  button: {
    borderRadius: scale(20),
    padding: scale(18),
  },
  buttonNo: {
    flex: 1,
    marginRight: scale(28),
    borderWidth: 1.5,
    borderColor: '#6F6F6F',
    color: '#3D3D3D',
  },
  buttonNoText: {
    ...gs.h3,
    fontWeight: 'bold',
    textAlign: 'center',
    textTransform: 'uppercase',
  },
  buttonYes: {
    flex: 1,
    backgroundColor: Colors.SECONDARY_COLORS.C400,
    borderWidth: 1.5,
    borderColor: Colors.SECONDARY_COLORS.C400,
  },
  buttonYesText: {
    ...gs.h3,
    color: 'white',
    fontWeight: 'bold',
    textAlign: 'center',
    textTransform: 'uppercase',
  },
  canelText: {
    ...gs.subTitle500,
    color: Colors.PRIMARY_COLORS.C700,
    marginBottom: scale(9),
    fontSize: scale(24),
  },
  cancelName: {
    ...gs.subTitle400,
    color: Colors.TEXT_COLORS.C800,
    marginBottom: scale(20),
    fontSize: scale(24),
  },
  activityTitle: {
    ...gs.subTitle400,
    marginBottom: scale(19),
  },

  confirmText: {
    ...gs.subTitle500,
    color: Colors.TEXT_COLORS.C800,
    marginBottom: scale(20),
    fontSize: scale(30),
  },
  buttonText: {
    color: Colors.TEXT_COLORS.C800,
    fontSize: scale(20),
    lineHeight: scale(23),
    fontStyle: 'normal',
    marginLeft: scale(2),
  },
})

export default ActivityFullWidthCard

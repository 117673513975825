import React, { useState, useCallback } from 'react'

const useComponentInfo = () => {
  const [info, setInfo] = useState(null)

  const onLayout = useCallback((event) => {
    const { width, height, x, y } = event.nativeEvent.layout
    // console.log({ useComponentInfo: 'useComponentInfo', width, height, x, y })
    setInfo({ width, height, x, y })
  }, [])

  return [info, onLayout]
}

export default useComponentInfo

import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import * as Localization from 'expo-localization'

import appStorage from '@app/services/app-storage.service'

import translationEN from './locales/en.json'
import translationES from './locales/es.json'
// import translationCA from './locales/ca.json'

const PERSISTENCE_KEY = 'APP_LANG'
// const BASE_URL = Constants.manifest.extra.BASE_URL
// const LANG_URL = BASE_URL.replace('/api/mobile', '')

// creating a language detection plugin using expo
// http://i18next.com/docs/ownplugin/#languagedetector
const languageDetector = {
  lng: 'es',
  type: 'languageDetector',
  async: true, // async detection
  detect: (cb) => {
    const lang = appStorage.get(PERSISTENCE_KEY)
    if (lang) {
      cb(lang)
    } else {
      Localization.getLocalizationAsync()
        .then(({ locale }) => {
          const newLocale = locale.split('-')[0]
          console.log('Localization', newLocale)
          cb(newLocale)
        })
        .catch(console.error)
    }
  },
  init: () => {},
  cacheUserLanguage: () => {},
}

i18n
  .use(languageDetector)
  .use(initReactI18next)
  .init(
    {
      debug: false,
      fallbackLng: `es`,
      preload: ['en', 'es'],
      // ns: [
      //   'translation',
      //   'common',
      //   'lang',
      //   'week',
      //   'months',
      //   'menu',
      //   'landingScreen',
      //   'loginScreen',
      //   'passwordReset',
      //   'homeScreen',
      //   'calendarScreen',
      //   'activityDetails',
      //   'activityBooking',
      //   'cashPaymentScreen',
      //   'bookingConfirmed',
      //   'activityReservedListScreen',
      //   'activityCancelConfirmedScreen',
      //   'activityReview',
      //   'paymentStripeScreen',
      //   'communityScreen',
      //   'referalsScreen',
      //   'walletScreen',
      //   'becomePartner',
      //   'authConfirmScreen',
      //   'signupScreen',
      //   'searchScreen',
      //   'partnerScreen',
      //   // 'walkthrough',
      //   'globalErrorScreen',
      //   'forceUpdateScreen',
      //   'onboarding',
      // ],
      saveMissing: false,
      interpolation: {
        escapeValue: false, // not needed for react!!
      },
      react: {
        bindI18n: 'languageChanged',
        bindI18nStore: '',
        transEmptyNodeValue: '',
        useSuspense: false,
      },
      resources: {
        en: {
          translation: {
            ...translationEN,
          },
        },
        es: {
          translation: {
            ...translationES,
          },
        },
        // ca: {
        //   ...translationCA,
        // },
      },
    },
    (err) => {
      console.log(err)
    },
  )

//@TODO: tmp disabling cause getting executed before load function
// i18n.on('languageChanged', function (lng) {
//   appStorage.set(PERSISTENCE_KEY, lng)
// })

// for (let ns of Object.keys(translationES)) {
//   i18n.addResources('es', ns, translationES[ns])
// }

// for (let ns of Object.keys(translationEN)) {
//   i18n.addResources('en', ns, translationEN[ns])
// }

// for (let ns of Object.keys(translationCA)) {
//   i18n.addResources('ca', ns, translationCA[ns])
// }

export default i18n

import React from 'react'
import { StyleSheet, View, Text, Pressable } from 'react-native'

import { scale, fScale } from '@app/atoms/scale-view'
import globalStyles from './global-style.atom'
import Colors from './colors'

function SecondaryButton({ containerStyle, children, icon, ...props }) {
  return (
    <Pressable style={[containerStyle, styles.container]} {...props}>
      <View style={styles.btn}>
        {icon ? icon : null}
        <Text style={styles.btnContent}>{children}</Text>
      </View>
    </Pressable>
  )
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  btn: {
    ...globalStyles.shadowStyleMain,
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 20,
    borderWidth: 1.5,
    borderColor: Colors.TEXT_COLORS.C600,
    marginHorizontal: 2,
    paddingVertical: scale(15.5),
    marginBottom: 10,
    backgroundColor: '#fff',
    flexDirection: 'row',
  },
  btnContent: {
    // flex: 1,
    color: Colors.TEXT_COLORS.C800,
    textAlign: 'center',
    fontFamily: 'roboto-medium',
    fontSize: fScale(28),
    lineHeight: fScale(28),
    letterSpacing: 0.02,
    padding: 0,
    margin: 0,
  },
})

export default SecondaryButton

import React, { useEffect, useRef, useState } from 'react'
import { Avatar, useChannelStateContext, useChatContext } from 'stream-chat-react'

import './MessagingChannelHeader.css'

import { TypingIndicator } from '../TypingIndicator/TypingIndicator'

import { ChannelInfoIcon, ChannelSaveIcon, getCleanImage, HamburgerIcon } from '../../assets'
import { Ionicons } from '@expo/vector-icons'
import { fScale, scale } from '@app/atoms/scale-view'
import Colors from '@app/atoms/colors'

const MessagingChannelHeader = (props) => {
  const { client } = useChatContext()
  const { channel } = useChannelStateContext()

  const [channelName, setChannelName] = useState(channel?.data.name || '')
  const [isEditing, setIsEditing] = useState(false)
  const [title, setTitle] = useState('')

  const inputRef = useRef()

  const members = Object.values(channel.state?.members || {}).filter((member) => member.user?.id !== client.user?.id)

  const updateChannel = async (e) => {
    if (e) e.preventDefault()

    if (channelName && channelName !== channel.data.name) {
      await channel.update({ name: channelName }, { text: `Channel name changed to ${channelName}` })
    }

    setIsEditing(false)
  }

  useEffect(() => {
    if (isEditing && inputRef.current) {
      inputRef.current.focus()
    }
  }, [isEditing])

  useEffect(() => {
    if (!channelName) {
      setTitle(members.map((member) => member.user?.name || member.user?.id || 'Unnamed User').join(', '))
    }
  }, [channelName, members])

  const EditHeader = () => (
    <form
      style={{ flex: 1 }}
      onSubmit={(e) => {
        e.preventDefault()
        inputRef.current.blur()
      }}>
      <input
        autoFocus
        className="channel-header__edit-input"
        onBlur={updateChannel}
        onChange={(e) => setChannelName(e.target.value)}
        placeholder="Type a new name for the chat"
        ref={inputRef}
        value={channelName}
      />
    </form>
  )

  return (
    <div className="messaging__channel-header">
      <div id="mobile-nav-icon" className={`${props.theme}`} onClick={() => props.toggleMobile()}>
        {/* <HamburgerIcon /> */}
        <Ionicons name="arrow-back" size={fScale(24)} color={Colors.TEXT_COLORS.C800} />
      </div>
      <div className="messaging__channel-header__avatars">
        <Avatar image={channel?.data?.image} name={channelName || title} size={40} />;
      </div>

      {!isEditing ? (
        <div className="channel-preview__content-wrapper">
          <div className="channel-preview__content-top">
            <p className="channel-preview__content-name channel-header__name">{channelName || title}</p>
          </div>

          <p className="channel-preview__content-message">{channel?.data?.preview_message || 'Send a message'}</p>
        </div>
      ) : (
        <EditHeader />
      )}
      <div className="messaging__channel-header__right">
        <TypingIndicator />
        {/* {channelName !== 'Social Demo' &&
          (!isEditing ? <ChannelInfoIcon {...{ isEditing, setIsEditing }} /> : <ChannelSaveIcon />)} */}
      </div>
    </div>
  )
}

export default React.memo(MessagingChannelHeader)

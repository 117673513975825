import _ from 'lodash'
import React from 'react'
import { Text, View, SafeAreaView, ScrollView, Image, StyleSheet, BackHandler, Platform } from 'react-native'
import { useNavigation, useRoute } from '@react-navigation/core'
import { useTranslation } from 'react-i18next'

import Icons from '@app/atoms/icons.atom'
import globalStyles from '@app/atoms/global-style.atom'
import Colors from '@app/atoms/colors'
import PrimaryButton from '@app/atoms/primary-button.atom'
import * as utils from '@app/utils'
import { scale, fScale } from '@app/atoms/scale-view'
import SecondaryButton from '@app/atoms/secondary-button.atom'
import { useStatusBar } from '@app/hooks/use-status-bar'
import useRefetchOnFocus from '@app/hooks/use-refetch-on-focus'
import routes from '@app/navigation/routes'

import gs from '@app/atoms/gs.atom'

function LoginScreen() {
  const { t } = useTranslation()
  const route = useRoute()
  const navigation = useNavigation()
  const statusBar = useStatusBar()

  const referralCode = _.get(route, 'params.referralCode', '')

  useRefetchOnFocus(
    React.useCallback(() => {
      // statusBar.alternateBackgroud()
    }, []),
  )

  return (
    <SafeAreaView style={{ flex: 1, backgroundColor: Colors.SECONDARY_COLORS.C50 }}>
      <ScrollView style={styles.scrollView}>
        <View>
          <Text style={[styles.title]}>{t('Hello!')}!</Text>
          <Text style={[styles.para]}>
            {t('Take control of your activities with this application designed for Vermut collaborators.')}
          </Text>
        </View>
        <View style={{ marginHorizontal: 5, marginBottom: 15 }}>
          <Text style={[gs.subTitle500, { fontFamily: 'roboto-medium', lineHeight: fScale(24.77) }]}>
            {t('Enter with')}
          </Text>

          <SecondaryButton
            containerStyle={{ marginTop: 10 }}
            onPress={() => {
              //   utils.track('Create Account Started', {})
              navigation.navigate(routes.LOGIN_WITH_MOBILE, { profile: { referralCode } })
            }}
            icon={<Icons.InputPhoneIcon width={scale(30)} height={scale(30)} strokeWidth="2.5" />}>
            <Text style={{ fontFamily: 'roboto-medium', fontSize: fScale(28) }}>{t('Your phone number')}</Text>
          </SecondaryButton>
        </View>
      </ScrollView>
    </SafeAreaView>
  )
}

const styles = StyleSheet.create({
  scrollView: {
    paddingHorizontal: scale(20),
  },
  title: {
    ...gs.title500,
    marginTop: scale(82),
    fontWeight: '500',
    fontSize: scale(30),
    lineHeight: fScale(35.16),
    color: Colors.TEXT_COLORS.C800,
  },
  para: {
    ...gs.textDetails400,
    marginTop: scale(17),
    marginBottom: scale(20),
    fontSize: scale(20),
    color: Colors.TEXT_COLORS.C800,
    lineHeight: fScale(23.44),
  },
})

export default LoginScreen

import React from 'react'
import { View, Text, StyleSheet, Modal, Pressable, Linking, TouchableOpacity } from 'react-native'
import { useTranslation } from 'react-i18next'
import Constants from 'expo-constants'
import { Chip } from 'react-native-paper'
import { MaterialIcons, AntDesign } from '@expo/vector-icons'

import Icons from '@app/atoms/icons.atom'
import Colors from '@app/atoms/colors'
import gs from '@app/atoms/gs.atom'
import { scale, fScale } from '@app/atoms/scale-view'

// const PHONE_NUMBER = Constants.manifest.extra.PHONE_NUMBER

// const PHONE_NUMBER_WITHOUT_ISD = PHONE_NUMBER?.replace('+34', '')

const PhoneContact = ({ type = 'chip', varient = false, phone_number }) => {
  const { t } = useTranslation()

  const [showModal, setShowModal] = React.useState(false)

  // let customStyle = [containerStyle]
  // let backBtnStyle = [styles.backBtn]

  // if (varient) {
  //   customStyle = [containerStyle, styles.varientStyle]
  //   backBtnStyle = [styles.backBtn, { fontFamily: 'roboto-medium', fontSize: fScale(16), lineHeight: fScale(18.75) }]
  // }

  return (
    <>
      <Modal
        animationType="slide"
        transparent={true}
        visible={showModal}
        onRequestClose={() => {
          // Alert.alert('Modal has been closed.')
          setShowModal(false)
        }}>
        <View style={modelStyles.centeredView}>
          <View style={modelStyles.modalView}>
            <TouchableOpacity onPress={() => setShowModal(false)} style={{ alignItems: 'flex-end' }}>
              <AntDesign name="closecircle" size={32} color="#A82137" />
            </TouchableOpacity>
            <Text style={[gs.subTitle500, { color: Colors.PRIMARY_COLORS.C700, padding: scale(10) }]}>
              {t(`homeScreen:How do you want to contact us?`)}
            </Text>
            <Text
              style={[gs.subTitle400, { color: Colors.TEXT_COLORS.C800, padding: scale(10), marginBottom: scale(22) }]}>
              {t('homeScreen:Choose one of the two options, we will be happy to talk to you.')}
            </Text>

            <View style={{ flexDirection: 'row' }}>
              <Pressable
                style={[modelStyles.button, { marginRight: scale(30) }]}
                onPress={() => Linking.openURL(`tel:${phone_number}`)}>
                <Icons.PhoneIcon width={fScale(24)} height={fScale(24)} strokeWidth={2} />
                <Text style={modelStyles.buttonText}>
                  {` `}
                  {t(`homeScreen:To call`)}
                </Text>
              </Pressable>
              <Pressable
                style={[modelStyles.button]}
                onPress={() => Linking.openURL(`whatsapp://send?text=hello&phone=${phone_number}`)}>
                <Icons.CommunityWhatsappIcon width={fScale(24)} height={fScale(24)} fill="#69AC6E" />
                <Text style={[modelStyles.buttonText, { color: '#69AC6E' }]}>
                  {` `}
                  {t(`homeScreen:WhatsApp`)}
                </Text>
              </Pressable>
            </View>
          </View>
        </View>
      </Modal>
    </>
  )
}

const styles = StyleSheet.create({
  phone: {
    backgroundColor: '#E0EDEF',
    // marginLeft: 25,
    justifyContent: 'center',
    alignItems: 'center',
    padding: scale(10),
    borderRadius: 700,
  },
})

const modelStyles = StyleSheet.create({
  centeredView: {
    flex: 1,
    justifyContent: 'center',
    backgroundColor: 'rgba(255, 253, 248, 0.9)',
  },
  modalView: {
    ...gs.shadow4,
    margin: scale(10),
    backgroundColor: 'white',
    borderRadius: scale(20),
    paddingVertical: scale(25),
    paddingHorizontal: scale(18),
  },
  buttonContainer: {
    flex: 1,
  },
  button: {
    borderRadius: scale(20),
    paddingVertical: scale(18),
    flex: 1,
    borderWidth: 2,
    borderColor: Colors.TEXT_COLORS.C800,
    color: Colors.TEXT_COLORS.C800,
    justifyContent: 'center',
    flexDirection: 'row',
  },
  buttonText: {
    ...gs.textDetails500,
    fontWeight: 'bold',
    textAlign: 'center',
  },
})

export default PhoneContact

import React, { useEffect } from 'react'
import { Avatar, useChatContext } from 'stream-chat-react'
import { Text, Pressable, TouchableOpacity, StyleSheet } from 'react-native'

import './MessagingChannelList.css'
import { SkeletonLoader } from './SkeletonLoader'
import { scale } from '@app/atoms/scale-view'
import gs from '@app/atoms/gs.atom'
import Colors from '@app/atoms/colors'

import { CreateChannelIcon } from '../../assets'
import streamLogo from '../../assets/stream.png'
import { useAuth } from '@app/hooks/use-auth'
import { useNavigation } from '@react-navigation/core'
import routes from '@app/navigation/routes'

const MessagingChannelList = ({ children, error = false, loading, onCreateChannel }) => {
  const { client, setActiveChannel } = useChatContext()
  const auth = useAuth()
  const navigation = useNavigation()

  // const { id, name = 'Example User' } = client.user || {};
  // const image = client?.user?.image || 'https://www.vermutapp.com/assets/front/images/logos/icon-192x192.png'
  // useEffect(() => {
  //   const getDemoChannel = async (client) => {
  //     const channel = client.channel('messaging', 'first', { name: 'Social Demo', demo: 'social' });
  //     await channel.watch();
  //     await channel.addMembers([client.user.id]);
  //     setActiveChannel(channel);
  //   };

  //   if (!loading && !children?.props?.children?.length) {
  //     getDemoChannel(client);
  //   }
  // }, [loading]); // eslint-disable-line

  const ListHeaderWrapper = ({ children }) => {
    return (
      <div className="messaging__channel-list">
        <div className="messaging__channel-list__header">
          <Avatar image={client?.user?.image} name={client?.user?.id} size={40} />
          <div className="messaging__channel-list__header__name">{client?.user?.name || client?.user?.id}</div>
          <button className="messaging__channel-list__header__button" onClick={onCreateChannel}>
            <CreateChannelIcon />
          </button>
        </div>
        {children}
        {/* <button onClick={auth.logout}>Logout</button> */}
        <TouchableOpacity
          style={[styles.whiteBtn, { marginLeft: scale(20), paddingHorizontal: scale(20), borderColor: '#941A35' }]}
          onPress={auth.logout}>
          <Text
            style={{
              ...gs.title400,
              color: '#941A35',
            }}>
            Logout
          </Text>
        </TouchableOpacity>
        <TouchableOpacity
          style={[styles.whiteBtn, { marginLeft: scale(20), paddingHorizontal: scale(20), borderColor: '#941A35' }]}
          onPress={() => navigation.navigate(routes.HOME_SCREEN)}>
          <Text
            style={{
              ...gs.title400,
              color: '#941A35',
            }}>
            Home
          </Text>
        </TouchableOpacity>
      </div>
    )
  }

  if (error) {
    return (
      <ListHeaderWrapper>
        <div className="messaging__channel-list__message">
          Error loading conversations, please try again momentarily.
        </div>
      </ListHeaderWrapper>
    )
  }

  if (loading) {
    return (
      <ListHeaderWrapper>
        <div className="messaging__channel-list__message">
          <SkeletonLoader />
        </div>
      </ListHeaderWrapper>
    )
  }

  return <ListHeaderWrapper>{children}</ListHeaderWrapper>
}

const styles = StyleSheet.create({
  whiteBtn: {
    ...gs.shadowStyleMain,
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: scale(20),
    borderWidth: 2,
    borderColor: '#fff',
    marginHorizontal: 2,
    paddingVertical: scale(2),
    marginBottom: scale(10),
    backgroundColor: '#fff',
    width: scale(100),
  },
})

export default React.memo(MessagingChannelList)

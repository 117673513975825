import _ from 'lodash'
import * as Yup from 'yup'
import React from 'react'
import {
  Text,
  View,
  SafeAreaView,
  ScrollView,
  Image,
  StyleSheet,
  ImageBackground,
  Pressable,
  Platform,
} from 'react-native'
import { useNavigation, useRoute } from '@react-navigation/core'
import { useTranslation } from 'react-i18next'
import * as Linking from 'expo-linking'
import { useFormik } from 'formik'
import RNOtpVerify from 'react-native-otp-verify'

import routes from '@app/navigation/routes'
import FormSubmitBtn from '@app/atoms/form-submit-btn.atom'
import Icons from '@app/atoms/icons.atom'
import globalStyles from '@app/atoms/global-style.atom'
import Colors from '@app/atoms/colors'
import PrimaryButton from '@app/atoms/primary-button.atom'
import * as utils from '@app/utils'
import { scale, fScale } from '@app/atoms/scale-view'
import SecondaryButton from '@app/atoms/secondary-button.atom'
import SectionBackButton from '@app/molecules/section-back-button.molecule'

import TextField from '@app/atoms/text-input.atom'
import { useAuth } from '@app/hooks/use-auth'
import PhoneField from '@app/atoms/phone-input.atom'
import { Ionicons } from '@expo/vector-icons'
import { useMutation } from 'react-query'
import api from '@app/services/api.service'

const PhoneOtpSchema = Yup.object().shape({
  phone: Yup.string().required('No phone provided.').min(10, 'please check phone digits.').trim(),
})

function LoginMobileScreen() {
  const { t } = useTranslation()
  const route = useRoute()
  const navigation = useNavigation()

  const appHash = React.useRef(null)
  const [otpError, setOtpError] = React.useState(false)

  const profile = _.get(route, 'params.profile', {})

  const phoneOtpMutation = useMutation(api.otp, {
    onSuccess: async (data, variables, context) => {
      navigation.navigate(routes.OTP_SCREEN, { ...variables, profile })
    },
    onError: async (errors, variables, context) => {
      // setErrors(errors)
      let errorMessage = _.get(errors, 'errors.phone[0]', 'not able to send sms')
      setOtpError(errorMessage)
    },
  })

  const PhoneOtpForm = useFormik({
    initialValues: {
      phone: '',
    },
    validationSchema: PhoneOtpSchema,
    onSubmit: (values) => {
      console.log({ ...values, smsHash: appHash.current })
      phoneOtpMutation.mutate({ ...values, smsHash: appHash.current })
    },
    validateOnChange: false,
    validateOnBlur: false,
  })

  const btnDisabled = PhoneOtpForm.values.phone.length < 8 ? true : false

  React.useEffect(() => {
    if (Platform.OS === 'android') {
      RNOtpVerify.getHash()
        .then((hash) => {
          appHash.current = hash.length > 0 ? hash[0] : null
        })
        .catch(console.log)
    }
  }, [])

  return (
    <SafeAreaView style={{ flex: 1, backgroundColor: Colors.SECONDARY_COLORS.C50 }}>
      <View
        style={{
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'center',
          marginBottom: scale(29),
          marginTop: scale(22),
          marginLeft: scale(21),
        }}>
        <SectionBackButton varient={true} />
      </View>
      <ScrollView style={[styles.scrollView]} contentContainerStyle={{}}>
        <View style={{ flex: 1, marginBottom: scale(20) }}>
          <Text style={[styles.title]}>{t('What is your mobile number?')}</Text>
          <Text style={[styles.para]}>
            {t('You will receive a 4-digit code sent by SMS to verify your mobile number.')}
          </Text>
          <Text style={[styles.miniText]}>{t('Mobile')}*</Text>
          <View>
            <PhoneField
              mode="outlined"
              autoCapitalize="none"
              keyboardType="phone-pad"
              name="phone"
              value={PhoneOtpForm.values.phone}
              onChangeText={PhoneOtpForm.handleChange('phone')}
              onBlur={PhoneOtpForm.handleBlur('phone')}
              error={PhoneOtpForm.errors.phone || otpError}
            />
          </View>
        </View>

        <PrimaryButton
          disabled={btnDisabled}
          isBusy={phoneOtpMutation.isLoading}
          onPress={(e) => {
            setOtpError(false)
            PhoneOtpForm.handleSubmit(e)
          }}
          containerStyle={{ paddingHorizontal: scale(20), marginVertical: scale(220) }}>
          <Text>{t('Continue')}</Text>
        </PrimaryButton>
      </ScrollView>
    </SafeAreaView>
  )
}

const styles = StyleSheet.create({
  scrollView: {
    paddingHorizontal: scale(20),
  },
  title: {
    fontFamily: 'Roboto',
    fontSize: fScale(24),
    lineHeight: fScale(24.77),
    color: Colors.TEXT_COLORS.C800,
    fontWeight: '500',
  },
  para: {
    fontFamily: 'roboto-regular',
    fontSize: fScale(20),
    color: Colors.TEXT_COLORS.C800,
    lineHeight: fScale(23.44),
    marginTop: scale(17),
    marginBottom: scale(17),
    fontWeight: '400',
  },
  miniText: {
    fontFamily: 'Roboto',
    fontSize: fScale(18),
    lineHeight: fScale(21),
    textAlign: 'left',
    fontWeight: '500',
    fontStyle: 'normal',
    color: Colors.TEXT_COLORS.C800,
  },
})

export default LoginMobileScreen

import _ from 'lodash'
import * as Yup from 'yup'
import React from 'react'
import {
  Text,
  View,
  SafeAreaView,
  ScrollView,
  Image,
  StyleSheet,
  ImageBackground,
  Pressable,
  TextInput,
  Platform,
} from 'react-native'
import { useNavigation, useRoute } from '@react-navigation/core'
import { useTranslation } from 'react-i18next'
import * as Linking from 'expo-linking'
import { useFormik } from 'formik'
import RNOtpVerify from 'react-native-otp-verify'

import routes from '@app/navigation/routes'
import FormSubmitBtn from '@app/atoms/form-submit-btn.atom'
import Icons from '@app/atoms/icons.atom'
import globalStyles from '@app/atoms/global-style.atom'
import Colors from '@app/atoms/colors'
import PrimaryButton from '@app/atoms/primary-button.atom'
import * as utils from '@app/utils'
import { scale, fScale } from '@app/atoms/scale-view'
import SectionBackButton from '@app/molecules/section-back-button.molecule'

import { useAuth } from '@app/hooks/use-auth'

// import OTPInputView from '@twotalltotems/react-native-otp-input'
import { useMutation, useQuery } from 'react-query'
import api from '@app/services/api.service'
import appStorage from '@app/services/app-storage.service'

import OtpInput from 'react-otp-input'

function OtpScreen() {
  const auth = useAuth()
  const route = useRoute()
  const { t } = useTranslation()
  const navigation = useNavigation()

  const phone = _.get(route, 'params.phone', '')
  const profile = _.get(route, 'params.profile', {})

  const appHash = React.useRef(null)
  const [otp, setOtp] = React.useState('')
  const [otpError, setOtpError] = React.useState(false)

  const phoneOtpVerifyMutation = useMutation(api.otpVerify, {
    onSuccess: async (data, variables, context) => {
      if (data && data.accessToken) {
        try {
          const userInfo = await api.userInfo()
          appStorage.set('userInfo', userInfo)
          auth.setAccessToken(data)
          let city_id = appStorage.get('city_id')
          if (!city_id) {
            const userStateId = userInfo.stateId || 0
            if ([8, 28, 53].includes(userStateId)) {
              appStorage.set('city_id', userStateId)
            } else {
              appStorage.set('city_id', 8)
            }
          }
          auth.setUser(userInfo)
        } catch (err) {}
      } else {
        if (profile.email) {
          let temp = { ...profile, ...variables, otp }
          navigation.navigate(routes.ONBOARDING_10, { profile: temp })
        } else {
          navigation.navigate(routes.ONBOARDING_7, { ...variables, otp })
        }
      }
    },
    onError: async (errors, variables, context) => {
      // setErrors(errors)
      setOtpError('invalid otp')
    },
  })

  const phoneOtpResendMutation = useMutation(api.otpResend, {
    onSuccess: async (data, variables, context) => {},
    onError: async (errors, variables, context) => {
      // setErrors(errors)
      // s5etOtpError('invalid otp')
    },
  })

  const otpHandler = (otpText) => {
    if (otpText) {
      const matches = otpText.match(/\d+/)
      if (matches && matches.length > 0) {
        setOtp(matches[0])
      }
    }
  }

  const startListeningForOtp = () =>
    RNOtpVerify.getOtp()
      .then((p) => RNOtpVerify.addListener(otpHandler))
      .catch((p) => console.log(p))

  const btnDisabled = otp.length < 4 ? true : false

  React.useEffect(() => {
    if (Platform.OS === 'android') {
      startListeningForOtp()

      RNOtpVerify.getHash()
        .then((hash) => {
          appHash.current = hash.length > 0 ? hash[0] : null
        })
        .catch(console.log)

      return () => RNOtpVerify.removeListener()
    }
  }, [])

  return (
    <SafeAreaView style={{ flex: 1, backgroundColor: Colors.SECONDARY_COLORS.C50 }}>
      <ScrollView style={styles.scrollView} contentContainerStyle={{}}>
        <View
          style={{
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'center',
            marginBottom: scale(29),
            marginTop: scale(22),
            marginLeft: scale(21),
          }}>
          <SectionBackButton varient={true} />
        </View>
        <View style={{ flex: 1, marginTop: scale(3), marginBottom: scale(20) }}>
          <Text style={[styles.inputTitle]}>{t('Verify your mobile number with the 4 digits')}</Text>
          <Text style={[styles.para]}>
            {t('Write the 4-digit code sent by SMS to')} {phone}{' '}
          </Text>

          <OtpInput
            value={otp}
            onChange={(code) => {
              setOtp(code)
            }}
            numInputs={4}
            isInputNum={true}
            inputStyle={{ width: scale(50), borderWidth: scale(1), height: scale(50), margin: scale(10) }} //"bfc-otp-input input-without-border-radius"
            // containerStyle= {{margin:scale(20)}}//"bfc-otp-container form__form-group-field justify-content-between"
            shouldAutoFocus={true}
            style={{ width: '80%', height: 60 }}
          />
          {otpError ? (
            <Text style={[globalStyles.errorText, { marginTop: 5, marginBottom: 10 }]}>{otpError}</Text>
          ) : null}
          <View style={{ marginBottom: scale(25) }}></View>
          {!phoneOtpResendMutation.isSuccess ? (
            <>
              <Text
                style={[
                  globalStyles.textBody20,
                  { color: Colors.TEXT_COLORS.C800, fontFamily: 'roboto-medium', marginBottom: scale(14) },
                ]}>
                {t('Have not you received the code?')}
              </Text>
              <Pressable
                onPress={() => {
                  if (phoneOtpResendMutation.isIdle) {
                    setOtpError(false)
                    phoneOtpResendMutation.mutate({ phone, smsHash: appHash.current })
                  }
                }}>
                <Text style={[globalStyles.textBody20, globalStyles.textLinkUnderline, { marginBottom: scale(28) }]}>
                  {t('Resend')}
                </Text>
              </Pressable>
            </>
          ) : null}

          {phoneOtpResendMutation.isSuccess ? (
            <Text
              style={[
                globalStyles.textBody20,
                { color: Colors.TEXT_COLORS.C800, fontFamily: 'roboto-medium', marginBottom: scale(14) },
              ]}>
              {t('SMS sent')}
            </Text>
          ) : null}

          <Text style={[globalStyles.textBody20, { color: Colors.TEXT_COLORS.C800, marginBottom: scale(10) }]}>
            {t('Verify that the mobile number is well written.')}
          </Text>
          <Text style={[globalStyles.textBody20, { color: Colors.TEXT_COLORS.C800, marginBottom: scale(40) }]}>
            {t('Call us at +34 634762055, if you have problems with the code.')}
          </Text>
        </View>

        <PrimaryButton
          disabled={btnDisabled}
          isBusy={phoneOtpVerifyMutation.isLoading}
          onPress={() => {
            //   utils.track('Create Account Started', {})
            setOtpError(false)
            if (otp) {
              phoneOtpVerifyMutation.mutate({ phone, otp })
            }
          }}
          containerStyle={{ paddingHorizontal: scale(20), marginTop: scale(200) }}>
          <Text>{t('Continue')}</Text>
        </PrimaryButton>
      </ScrollView>
    </SafeAreaView>
  )
}

const styles = StyleSheet.create({
  scrollView: {
    paddingHorizontal: scale(20),
  },
  inputTitle: {
    fontFamily: 'roboto-medium',
    fontSize: fScale(32),
    lineHeight: fScale(37.5),
    color: Colors.TEXT_COLORS.C800,
  },
  title: {
    fontFamily: 'roboto-medium',
    fontSize: fScale(32),
    lineHeight: fScale(37.5),
    color: Colors.TEXT_COLORS.C800,
    textAlign: 'center',
    marginTop: scale(45),
  },
  para: {
    fontFamily: 'roboto-regular',
    fontSize: scale(20),
    color: Colors.TEXT_COLORS.C800,
    // textAlign: 'center',
    marginTop: scale(17),
    marginBottom: scale(28),
  },
  borderStyleBase: {
    width: scale(60),
    height: scale(60),
  },

  underlineStyleBase: {
    width: scale(60),
    height: scale(60),
    borderRadius: 5,
    borderWidth: 0,
    backgroundColor: '#fff',
    fontSize: fScale(32),
    fontFamily: 'roboto-bold',
    color: Colors.TEXT_COLORS.C800,
  },

  borderStyleHighLighted: {},
  underlineStyleHighLighted: {},
})

export default OtpScreen

export const PRIMARY_LIST = {
  C50: '#FAE2E5',
  C100: '#F3B7BF',
  C200: '#E98A95',
  C300: '#DE5E6E',
  C400: '#D44152',
  C500: '#CA2C3A',
  C600: '#BB2739',
  C700: '#A82137',
  C800: '#941A35',
  C900: '#721130',
}
export const PRIMARY = PRIMARY_LIST.C700

export const SECONDARY_LIST = {
  C50: '#E0EDEF',
  C100: '#B1D2D6',
  C200: '#81B6BB',
  C300: '#519B9F',
  C400: '#2A888C',
  C500: '#0C696C',
  C600: '#0C696C',
  C700: '#095B5C',
  C800: '#064C4C',
  C900: '#033231',
}
export const SECONDARY = SECONDARY_LIST.C400

export const BACKGROUND_LIST = {
  C10: '#FFFDF8',
  C50: '#FCFAF3',
  C100: '#F8F6EF',
  C200: '#F2F0E9',
  C300: '#E4E2DB',
  C400: '#C1BFB9',
}
export const BACKGROUND = BACKGROUND_LIST.C10

export const TEXT_LIST = {
  C50: '#F9F9F9',
  C100: '#F3F3F3',
  C200: '#EAEAEA',
  C300: '#DBDBDB',
  C400: '#B7B7B7',
  C500: '#989898',
  C600: '#6F6F6F',
  C700: '#5C5C5C',
  C800: '#3D3D3D',
  C900: '#1D1D1D',
}
export const TEXT = TEXT_LIST.C800

const Colors = {
  PRIMARY_COLORS: PRIMARY_LIST,
  SECONDARY_COLORS: SECONDARY_LIST,
  TEXT_COLORS: TEXT_LIST,
  BACKGROUND_COLORS: BACKGROUND_LIST,
}

export default Colors

import _ from 'lodash'
import React, { useState } from 'react'
import * as Yup from 'yup'
import dayjs from 'dayjs'
import {
  Text,
  View,
  Image,
  StyleSheet,
  SafeAreaView,
  ScrollView,
  TouchableOpacity,
  useWindowDimensions,
  Modal,
  Pressable,
  FlatList,
  RefreshControl,
  Linking
} from 'react-native'
import { ActivityIndicator, Surface } from 'react-native-paper'
import RenderHtml from 'react-native-render-html'
import { useNavigation, useRoute } from '@react-navigation/core'
import { useTranslation } from 'react-i18next'
import { useMutation, useQuery, useInfiniteQuery } from 'react-query'
import gs from '@app/atoms/gs.atom'
import api from '@app/services/api.service'
import ImageCarousel from '@app/molecules/image-carousel.molecule'
import Colors from '@app/atoms/colors'
import RatingStar from '@app/atoms/rating-star.atom'
import Divider from '@app/atoms/divider.atom'
import ActivityFullWidthCard from '@app/molecules/activity-full-width-card.molecule'
import { scale } from '@app/atoms/scale-view'
import Icons from '@app/atoms/icons.atom'
import TextField from '@app/atoms/text-input.atom'
import { useFormik } from 'formik'
import PrimaryButton from '@app/atoms/primary-button.atom'
import ArrowButton from '@app/atoms/arrow-button.atom'
import { AntDesign } from '@expo/vector-icons'
import routes from '../../navigation/routes'
import CommentListScreen from './commentList'

const CommentSchema = Yup.object().shape({
  comments: Yup.string().required('Required'),
})

const SessionDetailsScreen = () => {
  const route = useRoute()
  const navigation = useNavigation()
  const { t } = useTranslation()
  const { width } = useWindowDimensions()
  const [enableValidation, setEnableValidation] = React.useState(false)
  const [showCommentModal, setCommentModal] = useState(false)
  const [timestamp, setTimestamp] = useState(Date.now())
  const [commentTimestamp, setCommentTimestamp] = useState(Date.now())
  const { id } = route.params
  const [refreshCommentList, setRefreshCommentList] = useState(false)

  const commentMuation = useMutation(api.addComment, {
    onSuccess: async (data, variables, context) => {
      commentForm.values.comments = ''
      setRefreshCommentList(true)
      setCommentModal(true)
      setCommentTimestamp(Date.now())
    },
    onError: async (errors, variables, context) => {
      console.log({ errors })
    },
  })

  const commentForm = useFormik({
    initialValues: {
      comments: '',
    },
    validationSchema: CommentSchema,
    onSubmit: async (values) => {
      setEnableValidation(true)
      commentMuation.mutate({ id: id, comment: values.comments })
    },
    validateOnChange: enableValidation,
    validateOnBlur: enableValidation,
  })

  React.useEffect(() => {
    sessionDetailsQuery.refetch()
  }, [timestamp])

  const sessionDetailsQuery = useQuery(['getSessionDetails', id], api.getSessionDetails, {
    enabled: !!id,
  })

  if (sessionDetailsQuery.isLoading) {
    return (
      <SafeAreaView style={{ flex: 1, justifyContent: 'center', alignItems: 'center' }}>
        <ActivityIndicator animating={true} />
      </SafeAreaView>
    )
  }

  const info = sessionDetailsQuery.data || {}
  const galleryImages = [info.logoUrl]
  const rating = info.averageRating && _.isNumber(info.averageRating) ? info.averageRating : 0

  const sessionDate = dayjs(info.startDatetime).format('ddd, D MMM YYYY')
  const sessionStartTime = dayjs(info.startDatetime).format('h:mm')
  const sessionEndTime = dayjs(info.endDatetime).format('h:mm')
  let cost = parseInt(info?.eventCost)
  cost = cost / 100
  return (
    <SafeAreaView style={{ flex: 1, backgroundColor: Colors.BACKGROUND_COLORS.C10 }}>
      <ScrollView>
        {/* <ImageCarousel images={galleryImages} platform={false} showBackBtn={false} /> */}
        <View style={styles.activityInfoContainer}>
          <View style={{ flex: 1, flexDirection: 'row', justifyContent: 'space-between' }}>
            <Text style={[styles.name, { textAlign: 'left', minHeight: scale(51) }]}>{info.activityName}</Text>
            <Text style={[styles.name, { textAlign: 'left', height: scale(51) }]}>{cost}€</Text>
          </View>
          <Text
            style={[
              styles.dateWeekName,
              { textAlign: 'left', textTransform: 'none', height: scale(46), color: '#3D3D3D', marginTop: scale(10) },
            ]}>
            {sessionDate}
          </Text>

          <Text
            style={[
              styles.dateWeekName,
              { textAlign: 'left', textTransform: 'none', height: scale(46), color: '#3D3D3D' },
            ]}>
            {sessionStartTime}h a {sessionEndTime}h
          </Text>
          <Text
            onClick={() => {
              Linking.openURL(`https://www.vermutapp.com/activities/${id}`)
            }}
            style={[
              styles.dateWeekName,
              { textAlign: 'left', textTransform: 'none', height: scale(46), color: 'blue', textDecorationLine: 'underline' },
            ]}>
            {t('see web activity')}
          </Text>
        </View>
        <View style={[gs.ph]}>
          <View
            style={{
              flex: 1,
              flexDirection: 'row',
              justifyContent: 'space-between',
              marginBottom: scale(20),
              marginTop: scale(20),
            }}>
            <Text style={[gs.h3, { fontWeight: '500' }]}>{t('participants')}</Text>
            <Text style={[gs.h3, { fontWeight: '500' }]}>{t('Attendees')}</Text>
          </View>
        </View>

        <ActivityFullWidthCard bookings={info?.bookings} setTimestamp={setTimestamp} />

        <View style={[gs.ph]}>
          <View style={{ flex: 1, flexDirection: 'row', justifyContent: 'space-between', marginTop: scale(10) }}>
            <Text style={[gs.h3, { fontWeight: '500' }]}>{t('Additional comments')}</Text>
          </View>
          <TextField
            // label={}
            // inputBackground={Colors.BACKGROUND_COLORS.C10}
            sectionStyle={{
              borderRadius: scale(20),
              borderColor: Colors.TEXT_COLORS.C400,
              borderWidth: 2,
              width: '100%',
              height: scale(124),
            }}
            inputStyle={{
              fontSize: scale(18),
              borderRadius: scale(20),
              height: scale(124),
              marginTop: scale(22),
              textAlignVertical: 'top',
              padding: 10,
              marginBottom: 27,
            }}
            // containerStyle={{ height: 1000 }}
            multiline
            numberOfLines={5}
            value={commentForm.values.comments}
            onChangeText={commentForm.handleChange('comments')}
            onBlur={commentForm.handleBlur('comments')}
            error={commentForm.errors.comments}
          />
          <View style={{ marginTop: scale(10), marginBottom: scale(21) }}>
            <PrimaryButton
              mode="contained"
              disabled={commentForm.isSubmitting || commentForm.values.comments == ''}
              onPress={() => {
                commentForm.handleSubmit()
              }}>
              {t('Keep and send')}
            </PrimaryButton>
          </View>
        </View>
        <Divider height={scale(10)} color={Colors.TEXT_COLORS.C200} containerStyle={{ marginBottom: scale(20) }} />
        <View style={[gs.ph]}>
          <View style={{ marginTop: scale(10), marginBottom: scale(21) }}>
            <ArrowButton mode="contained" onPress={() => navigation.navigate(routes.VALID_CARDS)}>
              {/* loading={becomePartnerMutation.isLoading} */}
              {t('valid cards')}
            </ArrowButton>
          </View>
        </View>

        <CommentListScreen id={id} commentTimestamp={commentTimestamp} />
      </ScrollView>
      {showCommentModal && (
        <SafeAreaView style={{ flex: 1, backgroundColor: Colors.BACKGROUND_COLORS.C50 }}>
          <Modal
            animationType="slide"
            transparent={true}
            visible={showCommentModal}
            onRequestClose={() => {
              // Alert.alert('Modal has been closed.')
              // setCancelActivity(null)
              // cancelActivityError(null)
            }}>
            <View style={modelStyles.centeredView}>
              {showCommentModal && (
                <View style={modelStyles.modalView}>
                  <View style={{ flex: 1, flexDirection: 'column', alignItems: 'center' }}>
                    <Icons.VermutsIcon />
                    <Text style={modelStyles.confirmText}>{t('It has been saved and sent successfully')}</Text>
                    <Text style={modelStyles.cancelName}>
                      {t('You have 1 hour after the activity to make changes.')}
                    </Text>
                    <View style={{ flexDirection: 'row', marginTop: scale(20) }}>
                      <Pressable
                        style={[modelStyles.button, modelStyles.buttonYes]}
                        onPress={() => setCommentModal(false)}>
                        <Text style={modelStyles.buttonYesText}>{t('It is understood!')}</Text>
                      </Pressable>
                    </View>
                  </View>
                </View>
              )}
            </View>
          </Modal>
        </SafeAreaView>
      )}
    </SafeAreaView>
  )
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  activityInfoContainer: {
    paddingLeft: scale(15),
    paddingRight: scale(10),
    paddingTop: scale(12),
    justifyContent: 'space-evenly',
    flexShrink: 1,
    backgroundColor: Colors.SECONDARY_COLORS.C50,
  },
  dateWeekName: {
    // minWidth: scale(106),
    textTransform: 'uppercase',
    fontFamily: 'Roboto',
    fontSize: scale(18),
    color: Colors.TEXT_COLORS.C800,
    textAlign: 'center',
    fontWeight: '500',
    lineHeight: scale(21.44),
  },
  name: {
    fontFamily: 'Roboto',
    fontSize: scale(24),
    color: Colors.TEXT_COLORS.C800,
    textAlign: 'center',
    fontWeight: '500',
    lineHeight: scale(24.77),
  },
})

const modelStyles = StyleSheet.create({
  centeredView: {
    flex: 1,
    justifyContent: 'center',
    backgroundColor: 'rgba(86, 86, 86, 0.9)',
  },
  modalView: {
    margin: scale(40),
    backgroundColor: '#FFFFFF',
    borderRadius: scale(30),
    paddingVertical: scale(25),
    paddingHorizontal: scale(20),
    shadowColor: '#000',
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.25,
    shadowRadius: 2,
    elevation: 2,
  },
  button: {
    borderRadius: scale(20),
    padding: scale(18),
  },
  buttonYes: {
    flex: 1,
    backgroundColor: Colors.SECONDARY_COLORS.C400,
    borderWidth: 1.5,
    borderColor: Colors.SECONDARY_COLORS.C400,
  },
  buttonYesText: {
    ...gs.h3,
    color: 'white',
    fontWeight: '500',
    textAlign: 'center',
    fontSize: scale(28),
  },
  canelText: {
    ...gs.subTitle500,
    color: Colors.PRIMARY_COLORS.C700,
    marginBottom: scale(9),
    fontSize: scale(24),
  },
  cancelName: {
    ...gs.subTitle400,
    color: Colors.TEXT_COLORS.C800,
    marginBottom: scale(20),
    fontSize: scale(20),
  },
  activityTitle: {
    ...gs.subTitle400,
    marginBottom: scale(19),
  },

  confirmText: {
    ...gs.subTitle500,
    color: Colors.TEXT_COLORS.C800,
    marginBottom: scale(10),
    fontSize: scale(24),
  },
})

export default SessionDetailsScreen

import React from 'react'
import { useTranslation } from 'react-i18next'
import { StyleSheet, Text, View } from 'react-native'
import { scale } from '@app/atoms/scale-view'
import gs from '@app/atoms/gs.atom'

function ActivityStatusInfo({ label, type }) {
  // type = exhausted, reserved, discount
  const { t } = useTranslation()

  // default is exhausted
  let backgroud = '#FFC700'
  let textColor = '#3D3D3D'

  if (type === 'reserved') {
    backgroud = '#2A888C'
    textColor = '#FFF'
  }

  if (type === 'discount') {
    backgroud = '#80B985'
    textColor = '#FFF'
  }

  return (
    <View
      style={{
        backgroundColor: backgroud,
        paddingHorizontal: scale(8),
        paddingVertical: scale(5),
        borderRadius: scale(20),
      }}>
      <Text
        style={[
          gs.textDetails500,
          {
            color: textColor,
            textTransform: 'uppercase',
            textAlign: 'center',
          },
        ]}>
        {t(label)}
      </Text>
    </View>
  )
}

const styles = StyleSheet.create({})

export default ActivityStatusInfo

import React from 'react'
import { View, Text, Dimensions, Image } from 'react-native'
// import FastImage from 'react-native-fast-image'
import { ActivityIndicator } from 'react-native-paper'

const ScreenDiensions = Dimensions.get('window')

const ImageWithProgress = ({ image }) => {
  const [loading, setLoading] = React.useState(false)
  const [errorLoading, setErrorLoading] = React.useState(false)

  let activityImg = errorLoading
    ? { uri: 'https://www.vermutapp.com/admin/img/no-image.png' }
    : typeof image === 'string'
    ? { uri: image }
    : image

  return (
    <View style={{ flex: 1 }}>
      <Image
        style={{ flex: 1, width: ScreenDiensions.width }}
        defaultSource={require('@app/assets/images/act-selected.png')}
        source={activityImg}
        resizeMethod="auto"
        resizeMode="cover"
        // priority: FastImage.priority.high,
        // cache: 'web',
        progressiveRenderingEnabled={true}
        onLoadStart={() => setLoading(true)}
        onError={() => {
          setLoading(false)
          setErrorLoading(true)
        }}
        onLoadEnd={() => setLoading(false)}
      />
      {loading && (
        <Text animating={true} style={{ color: '#fff', position: 'absolute', top: '40%', left: '45%' }}>
          Loading...
        </Text>
      )}
    </View>
  )
}

export default ImageWithProgress

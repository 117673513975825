import { StyleSheet, Pressable } from 'react-native'
import { useEffect } from 'react'

import EditScreenInfo from '../components/EditScreenInfo'
import { Text, View } from '../components/Themed'
import { authService } from '@app/services'
import { useAuth } from '@app/hooks/use-auth'
import { scale } from '@app/atoms/scale-view'
import gs from '@app/atoms/gs.atom'
import Colors from '@app/atoms/colors'
import { useTranslation } from 'react-i18next'
import routes from '@app/navigation/routes'

export default function HomeScreen({ navigation }) {
  const auth = useAuth()
  const { t } = useTranslation()

  return (
    <View style={styles.container}>
      <Text style={styles.title}>Tab One HomeScreen</Text>
      <View style={styles.separator} lightColor="#eee" darkColor="rgba(255,255,255,0.1)" />
      <EditScreenInfo path="/screens/HomeScreen.jsx" />
      <Pressable style={{ marginBottom: scale(100) }} onPress={() => auth.logout()}>
        <Text style={[gs.subTitle500, { color: Colors.PRIMARY_COLORS.C700, textAlign: 'center' }]}>
          {t('menu:Exit your session')}
        </Text>
      </Pressable>

      <Pressable
        style={{ marginBottom: scale(100) }}
        onPress={() => navigation.navigate(routes.CHAT, { profile: auth.user })}>
        <Text style={[gs.title, { color: 'white', textAlign: 'center' }]}>Chat</Text>
      </Pressable>
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
  },
  title: {
    fontSize: 20,
    fontWeight: 'bold',
  },
  separator: {
    marginVertical: 30,
    height: 1,
    width: '80%',
  },
})

import _ from 'lodash'
import React from 'react'
import { StyleSheet, View, TextInput, Image, Text } from 'react-native'
import MaskInput from 'react-native-mask-input'
import Icons from './icons.atom'
import { fScale, scale } from '@app/atoms/scale-view'

const iconsList = {
  alert: () => (
    <Text>
      <Icons.InputAlertIcon />
    </Text>
  ),
  email: () => (
    <Text>
      <Icons.InputEmailIcon />
    </Text>
  ),
  lock: () => (
    <Text>
      <Icons.InputLockIcon />
    </Text>
  ),
  person: () => (
    <Text>
      <Icons.InputPersonIcon strokeWidth={2.5} />
    </Text>
  ),
  phone: () => (
    <Text>
      <Icons.InputPhoneIcon />
    </Text>
  ),
}

function TextField({
  label,
  help,
  mode,
  icon,
  error,
  mask,
  containerStyle,
  sectionStyle,
  inputStyle,
  inputBackground = '#fff',
  ...inputProps
}) {
  // const leftIcon = icon ? getIconImage(icon) : null
  const leftIcon = icon ? <Text style={styles.imageStyle}>{icon}</Text> : null

  const errorText = _.isArray(error) ? error.join('. ') : _.isString(error) ? error : null
  const errorStyle = errorText ? styles.error : {}

  return (
    <View style={[containerStyle, { paddingBottom: 15 }]}>
      {label && (
        <Text>
          <Text style={styles.label}>{label}</Text> <Text style={styles.helpText}> {help}</Text>
        </Text>
      )}
      <View style={styles.container}>
        <View style={[styles.sectionStyle, sectionStyle, { backgroundColor: inputBackground }, errorStyle]}>
          {leftIcon}

          {mask ? (
            <MaskInput mask={mask} style={[inputStyle, { flex: 1 }]} {...inputProps} />
          ) : (
            <TextInput style={[inputStyle, { flex: 1 }]} {...inputProps} />
          )}
          {errorText ? (
            <View style={{ marginRight: 5 }}>
              <Icons.InputAlertIcon />
            </View>
          ) : null}
        </View>
      </View>
      {errorText && (
        <View style={styles.errorContainer}>
          <Icons.InputAlertIcon />
          <Text style={styles.errorText}>{errorText}</Text>
        </View>
      )}
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: 5,
  },
  label: {
    color: '#4F4F4F',
    fontFamily: 'roboto-medium',
    fontSize: scale(16),
    lineHeight: scale(18.75),
  },
  error: {
    backgroundColor: 'rgba(255, 172, 51, 0.2)',
    // borderRadius: 1,
    borderColor: '#EC9E2D',
  },
  helpText: {
    color: '#989898',
    fontFamily: 'roboto-regular',
    fontSize: scale(14),
    lineHeight: scale(16.41),
  },
  sectionStyle: {
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: '#fff',
    borderWidth: 1,
    borderColor: 'rgba(0,0,0,0.03)',
    minHeight: 45,
    borderRadius: 10,
    opacity: 0.8,
  },
  imageStyle: {
    justifyContent: 'center',
    alignItems: 'center',
    marginLeft: 10,
    height: 25,
    width: 25,
    color: 'red',
  },
  errorContainer: {
    // flex: 1,
    flexDirection: 'row',
    // flexWrap: 'wrap',
    marginTop: 2,
  },
  errorImage: {
    resizeMode: 'contain',
    position: 'absolute',
  },
  errorText: {
    color: '#EC9E2D',
    flexShrink: 1,
    flexWrap: 'wrap',
    marginLeft: 0,
    fontFamily: 'roboto-regular',
    fontSize: scale(14),
    marginTop: 1,
    // lineHeight: 18.41,
  },
})

export default TextField

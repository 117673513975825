/**
 * If you are not familiar with React Navigation, refer to the "Fundamentals" guide:
 * https://reactnavigation.org/docs/getting-started
 *
 */
import { FontAwesome } from '@expo/vector-icons'
import { createBottomTabNavigator } from '@react-navigation/bottom-tabs'
import * as React from 'react'
import { Pressable } from 'react-native'

import Colors from '@app/constants/Colors'
import useColorScheme from '@app/hooks/useColorScheme'
import HomeScreen from '@app/screens/HomeScreen'
import LoginScreen from '@app/screens/Login/login.screen'
import LoginMobileScreen from '@app/screens/Login/loginMobile.screen'
import OtpScreen from '@app/screens/Login/otp.screen'
import ProfileScreen from '@app/screens/ProfileScreen'
import TransactionScreen from '@app/screens/TransactionScreen'

/**
 * A bottom tab navigator displays tab buttons on the bottom of the display to switch screens.
 * https://reactnavigation.org/docs/bottom-tab-navigator
 */
const BottomTab = createBottomTabNavigator()

/*
 * When dev is true in .expo/settings.json (started via `start:dev`),
 * open Components tab as default.
 */
const initialTabRoute: string = __DEV__ ? 'HomeScreen' : 'HomeScreen'

const ROOT_ROUTES: string[] = ['HomeScreen', 'ProfileScreen', 'TransactionScreen']

const TabBarVisibilityOptions = ({ route }): BottomTabNavigationOptions => {
  const isNestedRoute = route.state?.index > 0
  const isRootRoute = ROOT_ROUTES.includes(route.name)

  return { tabBarVisible: isRootRoute && !isNestedRoute }
}

export default function BottomTabNavigator() {
  const colorScheme = useColorScheme()

  return (
    <BottomTab.Navigator
      initialRouteName={initialTabRoute}
      screenOptions={{
        tabBarActiveTintColor: Colors[colorScheme].tint,
        ...TabBarVisibilityOptions,
      }}>
      <BottomTab.Screen
        name="LoginPage"
        component={LoginScreen}
        options={({ navigation }) => ({
          title: 'Home',
          tabBarIcon: ({ color }) => <TabBarIcon name="code" color={color} />,
          headerRight: () => (
            <Pressable
              onPress={() => navigation.navigate('Modal')}
              style={({ pressed }) => ({
                opacity: pressed ? 0.5 : 1,
              })}>
              <FontAwesome name="info-circle" size={25} color={Colors[colorScheme].text} style={{ marginRight: 15 }} />
            </Pressable>
          ),
        })}
      />
      <BottomTab.Screen
        name="Login With Mobile"
        component={LoginMobileScreen}
        options={{
          title: 'Login With Mobile',
          tabBarIcon: ({ color }) => <TabBarIcon name="code" color={color} />,
        }}
      />
      <BottomTab.Screen
        name="OTP"
        component={OtpScreen}
        options={{
          title: 'OTP',
          tabBarIcon: ({ color }) => <TabBarIcon name="code" color={color} />,
        }}
      />
      <BottomTab.Screen
        name="TransactionScreen"
        component={TransactionScreen}
        options={{
          title: 'Transaction',
          tabBarIcon: ({ color }) => <TabBarIcon name="code" color={color} />,
        }}
      />
    </BottomTab.Navigator>
  )
}

/**
 * You can explore the built-in icon families and icons on the web at https://icons.expo.fyi/
 */
function TabBarIcon(props) {
  return <FontAwesome size={30} style={{ marginBottom: -3 }} {...props} />
}

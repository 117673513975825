import _ from 'lodash'

export const sendOTP = (res) => {
  let resData = {
    ...res,
  }
  return resData
}

export const verifyOTP = (res) => {
  let resData = {
    ...res,
  }
  return resData
}

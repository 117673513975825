import React from 'react'
import PropTypes from 'prop-types'
import { Text, View, StyleSheet, TouchableOpacity, BackHandler } from 'react-native'
import Colors from '@app/atoms/colors'
import lineHeight from '@app/atoms/line-height'
import { Ionicons } from '@expo/vector-icons'
import { TabActions } from '@react-navigation/core'
import { useTranslation } from 'react-i18next'
import { useNavigation, useRoute, CommonActions } from '@react-navigation/native'

import * as RootNavigation from '@app/navigation/root-ref'
import { fScale, scale } from '@app/atoms/scale-view'
import routes from '@app/navigation/routes'

function SectionBackButton({ containerStyle, customBack, varient = false }) {
  const navigation = useNavigation()
  const { t } = useTranslation()

  let customStyle = [containerStyle]
  let backBtnStyle = [styles.backBtn]

  if (varient) {
    customStyle = [containerStyle, styles.varientStyle]
    backBtnStyle = [styles.backBtn, { fontFamily: 'roboto-medium', fontSize: fScale(16), lineHeight: fScale(18.75) }]
  }

  const handleBackBtn = () => {
    if (RootNavigation.histroy.current) {
      const histroy = [...RootNavigation.histroy.current]
      const lastRoute = histroy.pop()

      // if (routes.CALENDAR_TAB == lastRoute.name || routes.ACTIVITY_SEARCH == lastRoute.name) {
      //   navigation.dispatch(
      //     CommonActions.reset({
      //       index: 1,
      //       routes: [{ name: routes.DRAWER_HOME }],
      //     })
      //   )
      //   return
      // }

      // if (lastRoute.params?.backTab === 'calendar') {
      //   const jumpToAction = TabActions.jumpTo(routes.CALENDAR_STACK, { jumpStack: routes.ACTIVITY_STACK })
      //   navigation.dispatch(jumpToAction)
      // } else {
      navigation.dispatch(CommonActions.goBack())
      // }
    } else {
      // navigation.dispatch(
      //   CommonActions.reset({
      //     index: 1,
      //     routes: [{ name: routes.DRAWER_HOME }],
      //   })
      // )
      navigation.dispatch(CommonActions.goBack())
    }
  }

  return (
    <View style={customStyle}>
      <TouchableOpacity
        style={styles.container}
        onPress={() => {
          if (customBack) {
            customBack()
          } else {
            handleBackBtn()
          }
        }}>
        <Ionicons name="arrow-back" size={fScale(24)} color={Colors.TEXT_COLORS.C800} />
        <Text style={backBtnStyle}>Go Back</Text>
      </TouchableOpacity>
    </View>
  )
}

SectionBackButton.propTypes = {
  // containerStyle: ViewPropTypes.style,
}

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    alignItems: 'center',
    height: scale(40),
  },
  backBtn: {
    paddingLeft: scale(5),
    fontFamily: 'roboto-regular',
    fontSize: fScale(14),
    lineHeight: fScale(24),
    color: Colors.TEXT_COLORS.C800,
  },
  varientStyle: {
    paddingHorizontal: scale(10),
    alignSelf: 'flex-start',
    backgroundColor: Colors.BACKGROUND_COLORS.C10,
    borderRadius: 24,
    borderWidth: 1,
    borderColor: Colors.TEXT_COLORS.C600,
  },
})

export default SectionBackButton

import _ from 'lodash'
import React from 'react'
import { useNavigation } from '@react-navigation/core'
import { createStackNavigator, CardStyleInterpolators } from '@react-navigation/stack'

import routes from '@app/navigation/routes'
import appStorage from '@app/services/app-storage.service'

import AppHeader from '@app/molecules/app-header.molecule'
import HomeScreen from '@app/screens/home'
import ChatScreen from '@app/screens/chat/index'
import SessionDetailsScreen from '@app/screens/home/details'
import ValidCardsScreen from '@app/screens/home/validCards'

const AuthStack = createStackNavigator()

function MainHomeNavigator(props) {
  const navigation = useNavigation()

  let authInitalRoute = routes.HOME_SCREEN
  let authInitalRouteParams = {}

  return (
    <AuthStack.Navigator
      {...props} // allow parent properties to be overridden
      presentation="card"
      initialRouteName={authInitalRoute}
      initialRouteParams={authInitalRouteParams}
      screenOptions={{
        gestureEnabled: false,
        headerShown: false,
        cardStyleInterpolator: CardStyleInterpolators.forHorizontalIOS,
      }}>
      <AuthStack.Screen
        name={routes.HOME_SCREEN}
        component={HomeScreen}
        {...props}
        options={{
          header: (headerProps) => (
            <AppHeader {...headerProps} showMenu={true} showBackButton={false} showRightMenu={true} />
          ),
          headerShown: true,
        }}
      />
      <AuthStack.Screen
        name={routes.CHAT}
        component={ChatScreen}
        {...props}
        options={{
          header: (headerProps) => <AppHeader {...headerProps} />,
          // headerShown: true,
        }}
      />
      <AuthStack.Screen
        name={routes.SESSION_DEATILS}
        component={SessionDetailsScreen}
        {...props}
        options={{
          header: (headerProps) => (
            <AppHeader {...headerProps} showMenu={false} showBackButton={true} showRightMenu={true} />
          ),
          headerShown: true,
        }}
      />
      <AuthStack.Screen
        name={routes.VALID_CARDS}
        component={ValidCardsScreen}
        {...props}
        options={{
          header: (headerProps) => (
            <AppHeader {...headerProps} showMenu={false} showBackButton={true} showRightMenu={true} />
          ),
          headerShown: true,
        }}
      />
    </AuthStack.Navigator>
  )
}

export default MainHomeNavigator

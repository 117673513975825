import _ from 'lodash'
import React from 'react'
import { useNavigation } from '@react-navigation/core'
import { createStackNavigator, CardStyleInterpolators } from '@react-navigation/stack'

import routes from '@app/navigation/routes'
import appStorage from '@app/services/app-storage.service'

import LandingScreen from '@app/screens/Login/landing.screen'
import LoginScreen from '@app/screens/Login/login.screen'
import LoginMobileScreen from '@app/screens/Login/loginMobile.screen'
import OtpScreen from '@app/screens/Login/otp.screen'

import ForceUpdateScreen from '@app/screens/ForceUpdateScreen'

const AuthStack = createStackNavigator()

function AuthStackNavigator(props) {
  const navigation = useNavigation()

  let authInitalRoute = routes.LANDING_SCREEN
  let authInitalRouteParams = {}

  const isOnboardingInfoDone = appStorage.get('onboarding_info_done')

  if (isOnboardingInfoDone === true) {
    authInitalRoute = routes.LOGIN_SCREEN
  }
  return (
    <AuthStack.Navigator
      {...props} // allow parent properties to be overridden
      presentation="card"
      initialRouteName={authInitalRoute}
      initialRouteParams={authInitalRouteParams}
      screenOptions={{
        gestureEnabled: false,
        headerShown: false,
        cardStyleInterpolator: CardStyleInterpolators.forHorizontalIOS,
      }}>
      <AuthStack.Screen {...props} name={routes.LANDING_SCREEN} component={LandingScreen} />
      <AuthStack.Screen {...props} name={routes.LOGIN_SCREEN} component={LoginScreen} />
      <AuthStack.Screen {...props} name={routes.LOGIN_WITH_MOBILE} component={LoginMobileScreen} />
      <AuthStack.Screen name={routes.OTP_SCREEN} component={OtpScreen} {...props} />

      <AuthStack.Screen name={routes.FORCE_UPDATE} component={ForceUpdateScreen} {...props} />
      <AuthStack.Screen name={routes.NOT_FOUND} component={LoginScreen} {...props} />
    </AuthStack.Navigator>
  )
}

export default AuthStackNavigator

import React from 'react'
import PropTypes from 'prop-types'
import { Text, View, StyleSheet } from 'react-native'
import { TEXT_LIST, SECONDARY_LIST } from '@app/atoms/colors'
import lineHeight from '@app/atoms/line-height'
import { scale } from '@app/atoms/scale-view'
import gs from '@app/atoms/gs.atom'

function HeadSection({ title, body, containerStyle }) {
  return (
    <View style={[{ backgroundColor: SECONDARY_LIST.C50 }, containerStyle]}>
      <Text style={styles.heading}>{title}</Text>
      {/* <Text style={styles.body}>{body}</Text> */}
    </View>
  )
}

const styles = StyleSheet.create({
  heading: {
    ...gs.title500,
  },
  body: {
    fontFamily: 'roboto-regular',
    fontSize: scale(20),
    lineHeight: scale(23.44),
    color: TEXT_LIST.C800,
  },
})

HeadSection.propTypes = {
  title: PropTypes.string.isRequired,
  body: PropTypes.string.isRequired,
  // containerStyle: ViewPropTypes.style,
}

export default HeadSection

import React from 'react'
import { useNavigation, useRoute, TabActions } from '@react-navigation/core'
import { Dimensions, StyleSheet, Text, TouchableOpacity, View, BackHandler } from 'react-native'
// import Carousel, { Pagination } from 'react-native-snap-carousel'
// import { Ionicons } from '@expo/vector-icons'
// import FastImage from 'react-native-fast-image'

import Colors from '@app/atoms/colors'
import ImageWithProgress from '@app/atoms/image-with-progress.atom'
import gs from '@app/atoms/gs.atom'
import routes from '@app/navigation/routes'
import Icons from '@app/atoms/icons.atom'
import { scale } from '@app/atoms/scale-view'

const ScreenDiensions = Dimensions.get('window')

function renderCarouselSlide({ item, index }) {
  return (
    <View style={styles.slide}>
      <ImageWithProgress image={item.image} />

      {item.platform ? (
        <View style={styles.platform}>
          <Text style={[gs.textDetails400]}>{item.platform}</Text>
        </View>
      ) : null}
    </View>
  )
}

const ImageCarousel = ({ images, platform, showBackBtn = true }) => {
  const route = useRoute()
  const navigation = useNavigation()
  const carousel = React.useRef(null)
  const [activeSlide, setActiveSlide] = React.useState(0)

  const gallery = images.map((i) => {
    return {
      image: i,
      platform,
    }
  })

  const handleBackBtn = () => {
    if (route.params?.backTab === 'calendar') {
      const jumpToAction = TabActions.jumpTo(routes.CALENDAR_STACK)
      navigation.dispatch(jumpToAction)
    } else if (route.params?.backTab === 'search') {
      const jumpToAction = TabActions.jumpTo(routes.ACTIVITY_SEARCH)
      navigation.dispatch(jumpToAction)
    } else {
      navigation.goBack()
    }

    return true
  }

  React.useEffect(() => {
    if (route.params?.backTab) {
      const backHandler = BackHandler.addEventListener('hardwareBackPress', handleBackBtn)

      return () => backHandler.remove()
    }
  }, [])

  return (
    <View style={styles.container}>
      {showBackBtn ? (
        <TouchableOpacity style={[gs.shadowStyleMain, styles.backArrow]} onPress={handleBackBtn}>
          <Icons.BackArrowCircleIcon />
        </TouchableOpacity>
      ) : null}
      {/* <Carousel
        ref={carousel}
        data={gallery}
        sliderWidth={ScreenDiensions.width}
        itemWidth={ScreenDiensions.width}
        loop={false}
        renderItem={renderCarouselSlide}
        onSnapToItem={(index) => setActiveSlide(index)}
      />
      <Pagination
        dotsLength={gallery.length}
        activeDotIndex={activeSlide}
        containerStyle={styles.paginationStyle}
        dotStyle={{
          width: 6,
          height: 6,
          borderRadius: 5,
          marginHorizontal: 0,
          backgroundColor: Colors.TEXT_COLORS.C500,
        }}
        inactiveDotStyle={{
          backgroundColor: '#fff',
        }}
        inactiveDotOpacity={1}
        inactiveDotScale={1}
      /> */}
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: '#000',
    minHeight: scale(60),
  },
  slide: {
    flex: 1,
    width: ScreenDiensions.width,
    height: scale(225),
  },
  backgroudImage: {
    flex: 1,
  },
  backArrow: {
    position: 'absolute',
    top: scale(15),
    left: scale(20),
    zIndex: 1,
  },
  platform: {
    ...gs.shadow4,
    backgroundColor: '#fff',
    paddingVertical: scale(5),
    paddingHorizontal: scale(10),
    borderRadius: scale(20),
    position: 'absolute',
    right: scale(20),
    top: scale(15),
  },
  paginationStyle: {
    position: 'absolute',
    bottom: scale(24),
    alignSelf: 'center',
    paddingHorizontal: 0,
    paddingVertical: 0,
  },
})

export default ImageCarousel

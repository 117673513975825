import * as yup from 'yup'

export const sendOTP = yup.object().shape({
  phone: yup.string().required('Please enter phone number'),
})

export const verifyOTP = yup.object().shape({
  phone: yup.string().required('Please enter phone number'),
  otp: yup.string().required('Please enter otp'),
})

import { Dimensions } from 'react-native'

const { width, height, fontScale } = Dimensions.get('window')
const scaledSize = Dimensions.get('window')

const guidelineBaseWidth = 414
const guidelineBaseHeight = 736

export const scale = (size) => {
  return size
}

export const fScale = (size) => scale(size / fontScale)

export const verticalScale = (size) => height * size

export default {
  scale,
  fScale,
  verticalScale,
}

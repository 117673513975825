import React, { useContext, createContext } from 'react'
import Colors from '@app/atoms/colors'

const statusBarContext = createContext()

export function StatusBarProvider({ children }) {
  const statusBar = useProvideStatusBar()
  return <statusBarContext.Provider value={statusBar}>{children}</statusBarContext.Provider>
}

export const useStatusBar = () => {
  return useContext(statusBarContext)
}

function useProvideStatusBar() {
  const [hidden, setHidden] = React.useState(false)
  const [background, setBackground] = React.useState(Colors.BACKGROUND_COLORS.C10)

  const mainBackground = () => setBackground(Colors.BACKGROUND_COLORS.C10)
  const alternateBackgroud = () => setBackground(Colors.SECONDARY_COLORS.C50)

  return { background, hidden, mainBackground, alternateBackgroud, setHidden }
}

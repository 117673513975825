import { StyleSheet, Pressable, Text, View, SafeAreaView } from 'react-native'
import React, { useEffect, useState } from 'react'
import { useQuery, useQueryClient, useInfiniteQuery } from 'react-query'
import { ActivityIndicator } from 'react-native-paper'
import { useAuth } from '@app/hooks/use-auth'
import { scale } from '@app/atoms/scale-view'
import gs from '@app/atoms/gs.atom'
import Colors from '@app/atoms/colors'
import { useTranslation } from 'react-i18next'
import routes from '@app/navigation/routes'
// import { Switch } from 'antd'
// import 'antd/dist/antd.css'
import globalStyles from '@app/atoms/global-style.atom'
import ActivityDateSection from '@app/molecules/activity-date-section.molecule'
import useComponentInfo from '@app/hooks/use-component-info'
import api from '@app/services/api.service'
import _ from 'lodash'

export default function HomeScreen({ navigation }) {
  const auth = useAuth()
  const { t } = useTranslation()
  const [checked, setChecked] = useState(false)
  const onEndReachedCalledDuringMomentum = React.useRef(false)
  const [activityDateSectionLayout, onActivityDateSectionLayout] = useComponentInfo()
  const activityDetailsQuery = useQuery(['list'], api.getActivityDates, {
    getNextPageParam: (lestestPage, pages) => {
      const { currentPage, lastPage } = lestestPage.meta
      const nextPage = currentPage + 1
      return nextPage <= lastPage ? nextPage : undefined
    },
  })
  const activity = activityDetailsQuery?.data || {}
  const activityData = activity?.data || []
  // React.useEffect(() => {

  // }, [])
  const handleLoadMore = ({ distanceFromEnd }) => {
    console.log({ distanceFromEnd })
    if (!onEndReachedCalledDuringMomentum.current) {
      activityList.fetchNextPage()
      onEndReachedCalledDuringMomentum.current = true
    }
  }
  if (activityDetailsQuery.isLoading) {
    return (
      <SafeAreaView style={{ flex: 1, justifyContent: 'center', alignItems: 'center' }}>
        <ActivityIndicator animating={true} />
      </SafeAreaView>
    )
  }
  return (
    <View style={styles.container}>
      {/* <Switch checkedChildren="Lista" unCheckedChildren="Calendario" defaultChecked /> */}
      <View onLayout={onActivityDateSectionLayout}>
        <Text style={[styles.reserveSectionTitle, { marginHorizontal: 20 }]}>{t(`Upcoming reservations`)}:</Text>
        {activityData.length == 0 &&
          <View style={[styles.emptyView]}>
            <Text style={[styles.emptyText]}>{t('No Reservations Available')} </Text>
          </View>
        }
        {_.map(activityData, (item, index) => {
          return <ActivityDateSection key={index} activity={item} />
        })}
      </View>
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    // alignItems: 'center',
    // justifyContent: 'center',
  },
  title: {
    fontSize: 20,
    fontWeight: 'bold',
  },
  separator: {
    marginVertical: 30,
    height: 1,
    width: '80%',
  },
  reserveSectionTitle: {
    ...globalStyles.cardTitle,
    fontFamily: 'Robot',
    marginBottom: scale(8),
    fontSize: scale(30),
    lineHeight: scale(35.16),
  },
  emptyText: {
    fontFamily: 'Robot',
    fontSize: scale(24),
    lineHeight: scale(25.16),
    textAlign: 'center',
    color:'#989898'
  },
  emptyView: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
    marginTop: scale(20)
  }
})

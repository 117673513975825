/**
 * If you are not familiar with React Navigation, refer to the "Fundamentals" guide:
 * https://reactnavigation.org/docs/getting-started
 *
 */
import { NavigationContainer, DefaultTheme, DarkTheme } from '@react-navigation/native'
import * as React from 'react'
import LinkingConfiguration from './LinkingConfiguration'
import RootNavigator from './RootNavigator'
import * as RootNavigation from './root-ref'
import { View, ActivityIndicator } from 'react-native'
import { useAuth } from '@app/hooks/use-auth'

const LinkingFallback = () => {
  return (
    <View style={{ flex: 1, justifyContent: 'center', alignItems: 'center' }}>
      <ActivityIndicator size="large" />
    </View>
  )
}

export default function Navigation({ colorScheme }) {
  const auth = useAuth()
  const routeNameRef = React.useRef()

  return (
    <NavigationContainer
      ref={RootNavigation.navigationRef}
      onStateChange={async (state) => {
        // console.log('\n\n=============onStateChange=========');
        // console.log('state-->', state);
        // console.log('\n',
        //    treeify.asTree(state, true)
        // );

        const previousRouteName = routeNameRef.current
        const currentRoute = RootNavigation.getCurrentRoute()

        console.log('NavigationContainer--->currentRoute-->', currentRoute)

        // console.info(currentRoute)
        if (previousRouteName !== currentRoute.name) {
          if (auth.user && auth.user?.id) {
            let userInfo = {
              ...auth.user,
              address: {
                street: auth?.user?.address ? auth?.user?.address : '',
                city: auth?.user?.city ? auth?.user?.city : '',
                state: auth?.user?.state ? auth?.user?.state : '',
                country: auth?.user?.country ? auth?.user?.country : '',
              },
            }
            // utils.track('identity', { userInfo: userInfo })
          }
          // utils.track('screen', {
          //   routeName: currentRoute.name,
          //   // routeParams: currentRoute.params || {},
          //   // userInfo: auth.user || {},
          // })
        }

        routeNameRef.current = currentRoute.name
        if (RootNavigation.histroy.current) {
          RootNavigation.histroy.current.push(currentRoute)
        } else {
          RootNavigation.histroy.current = [currentRoute]
        }
      }}
      linking={LinkingConfiguration}
      fallback={<LinkingFallback />}
      theme={colorScheme === 'dark' ? DarkTheme : DefaultTheme}>
      <RootNavigator />
    </NavigationContainer>
  )
}

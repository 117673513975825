import _ from 'lodash'
import React, { useState, useEffect, useContext, createContext } from 'react'
import { useQueryClient, useMutation } from 'react-query'
import api from '@app/services/api.service'
import * as utils from 'src/utils'
import appStorage from '@app/services/app-storage.service'

const authContext = createContext()

export function AuthProvider({ children }) {
  const auth = useProvideAuth()
  return <authContext.Provider value={auth}>{children}</authContext.Provider>
}

export const useAuth = () => {
  return useContext(authContext)
}

function useProvideAuth() {
  const queryClient = useQueryClient()

  const storedUserInfo = appStorage.get('userInfo')
  const storedAccessToken = appStorage.get('accessToken')

  const [errors, setErrors] = useState(null)
  const [user, setUser] = useState(storedUserInfo)
  const [accessToken, setAccessToken] = useState(storedAccessToken)

  const reloadUser = async () => {
    try {
      const userInfo = await api.userInfo()
      let info = {
        ...userInfo,
        address: {
          street: userInfo?.address ? userInfo?.address : '',
          city: userInfo?.city ? userInfo?.city : '',
          state: userInfo?.state ? userInfo?.state : '',
          country: userInfo?.country ? userInfo?.country : '',
        },
      }
      // utils.track('identity', { userInfo: info })
      appStorage.set('userInfo', userInfo)

      // console.log('userInfo-->', userInfo.stateId);

      let city_id = appStorage.get('city_id')
      if (!city_id) {
        const userStateId = userInfo.stateId || 0
        if ([8, 28, 53].includes(userStateId)) {
          appStorage.set('city_id', userStateId)
        } else {
          appStorage.set('city_id', 8)
        }
      }

      setUser(userInfo)
    } catch (e) {
      console.log(e)
    }
  }

  const doLogin = useMutation(api.login, {
    onSuccess: async (data, variables, context) => {
      const userInfo = await api.userInfo()
      appStorage.set('userInfo', userInfo)
      setAccessToken(data.accessToken)
      let city_id = appStorage.get('city_id')
      if (!city_id) {
        const userStateId = userInfo.stateId || 0
        if ([8, 28, 53].includes(userStateId)) {
          appStorage.set('city_id', userStateId)
        } else {
          appStorage.set('city_id', 8)
        }
      }

      setUser(userInfo)

      let info = {
        ...userInfo,
        address: {
          street: userInfo?.address ? userInfo?.address : '',
          city: userInfo?.city ? userInfo?.city : '',
          state: userInfo?.state ? userInfo?.state : '',
          country: userInfo?.country ? userInfo?.country : '',
        },
      }
      // utils.track('identity', { userInfo: info })
      // utils.track('User Logged In', {
      //   login_method: variables['oauthType'] || 'Email',
      // })
    },
    onError: async (errors, variables, context) => {
      setErrors(errors)
    },
  })

  const doSignup = useMutation((data) => api.signup({ city_id: 0, ...data }), {
    onSuccess: async (data, variables, context) => {
      setAccessToken(data.accessToken)
    },
    onError: async (errors, variables, context) => {
      setErrors(errors)
    },
  })

  const doResetEmail = useMutation(api.resetPassword, {
    onSuccess: async (data, variables, context) => {},
    onError: async (errors, variables, context) => {
      // setErrors(errors)
    },
  })

  const doResetPasswordToken = useMutation(api.resetPasswordToken, {
    onSuccess: async (data, variables, context) => {},
    onError: async (errors, variables, context) => {
      // setErrors(errors)
    },
  })

  const logout = async () => {
    appStorage.set('userInfo', null)
    appStorage.set('accessToken', null)
    appStorage.set('city_id', null)

    setAccessToken(null)
    setUser(null)
    setErrors(null)
    // utils.track('User Logged Out', {})
    // Segment.reset()
    // try {
    //   await GoogleSignIn.signOutAsync()
    // } catch (e) {
    //   console.error(e)
    // }
    // try {
    //   await FB.logOutAsync()
    // } catch (e) {
    //   console.error(e)
    // }
    try {
      queryClient.clear()
    } catch (e) {
      console.error(e)
    }
  }

  useEffect(() => {
    if (accessToken && accessToken != 'null') {
      reloadUser()
    }
  }, [accessToken])

  return {
    errors,
    accessToken,
    user,
    reloadUser,
    login: doLogin,
    signup: doSignup,
    resetEmail: doResetEmail,
    resetPasswordToken: doResetPasswordToken,
    logout,
    setUser,
    setAccessToken,
    // signup,
    // signout,
    // sendPasswordResetEmail,
    // confirmPasswordReset,
  }
}

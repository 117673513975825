import { StatusBar } from 'expo-status-bar';
import { SafeAreaProvider } from 'react-native-safe-area-context';
import { QueryClient, QueryClientProvider } from 'react-query'

import useCachedResources from '@app/hooks/useCachedResources';
import useColorScheme from '@app/hooks/useColorScheme';
import Navigation from '@app/navigation';
import { AuthProvider } from '@app/hooks/use-auth'

export default function App() {
  const isLoadingComplete = useCachedResources();
  const colorScheme = useColorScheme();
  const queryClient = new QueryClient()

  if (!isLoadingComplete) {
    return null;
  } else {
    return (
      <QueryClientProvider client={queryClient}>
        <SafeAreaProvider>
          <AuthProvider>

            <Navigation colorScheme={colorScheme} />
            <StatusBar />
          </AuthProvider>

        </SafeAreaProvider>
      </QueryClientProvider>
    );
  }
}

import React from 'react'
import { StyleSheet, View, Text, Pressable } from 'react-native'
import { LinearGradient } from 'expo-linear-gradient'
import { ActivityIndicator, Colors } from 'react-native-paper'

import { scale, fScale } from '@app/atoms/scale-view'
import globalStyles from './global-style.atom'

function PrimaryButton({ containerStyle, children, disabled = false, isBusy = false, ...props }) {
  let gradients = ['#519B9F', '#519B9F']
  let btnTextStyle = [styles.btnContent]

  if (disabled) {
    gradients = ['#EAEAEA', '#EAEAEA']
    btnTextStyle = [styles.btnContent, { color: '#DBDBDB' }]
  }

  return (
    <Pressable style={[containerStyle, styles.container]} {...props}>
      <LinearGradient colors={gradients} style={[styles.btn]}>
        <Text style={btnTextStyle}>{isBusy ? <ActivityIndicator animating={true} color="#fff" /> : children}</Text>
      </LinearGradient>
    </Pressable>
  )
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  btn: {
    ...globalStyles.shadowStyleMain,
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 20,
    marginHorizontal: 2,
    paddingVertical: scale(15.5),
    marginBottom: 10,
  },
  btnContent: {
    // flex: 1,
    color: '#fff',
    textAlign: 'center',
    fontFamily: 'roboto-medium',
    fontSize: scale(28),
    lineHeight: scale(32),
    letterSpacing: 0.02,
    padding: 0,
    margin: 0,
  },
})

export default PrimaryButton

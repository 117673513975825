import React from 'react'
import { View } from 'react-native'
import { TEXT_LIST } from './colors'

function Divider({ containerStyle, height, color }) {
  const backgroundColor = color ? color : TEXT_LIST.C500

  return (
    <View
      style={[
        { marginTop: 20, marginBottom: 20 },
        containerStyle,
        {
          flex: 0,

          alignItems: 'center',
          justifyContent: 'center',
        },
      ]}>
      <View
        style={{
          height: height || 1.5,
          width: '100%',
          backgroundColor,
        }}></View>
    </View>
  )
}

Divider.propTypes = {
  // containerStyle: ViewPropTypes.style,
}

export default Divider

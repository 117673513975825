import { get, mapValues } from 'lodash'
import normalize from 'normalize-object'
import Constants from 'expo-constants'
import * as navigation from '@app/navigation/root-ref'

import i18n from '@app/config/i18next'
import appStorage from '@app/services/app-storage.service'
import { BASE_URL } from '@env'

const API_BASE_URL = `${BASE_URL}/api`

console.log('API_BASE_URL====>', API_BASE_URL)

function getJsonFromText(str) {
  let obj = false
  try {
    obj = JSON.parse(str)
  } catch (e) {
    return false
  }
  return obj
}

function executeHttp({ endpoint, ...args }) {
  return async (query) => {
    const localId = i18n.language || i18next.languages[0]
    const cityId = appStorage.get('city_id') || 0
    const method = args.type ? args.type : 'GET'

    let url = API_BASE_URL + (typeof endpoint === 'function' ? endpoint(query) : endpoint)

    console.log('url--->after--->', url)

    let options = {
      method,
      headers: {
        Accept: 'application/json',
        // 'Content-Type': 'application/json',
        'X-app-version': Constants.manifest.version,
        'X-localization': localId,
        'X-city-id': cityId,
      },
    }

    let authToken = appStorage.get('accessToken') || null

    if (authToken) {
      options.headers['authorization'] = `Bearer ${authToken}`
    }

    // console.log({ query, args, url })
    // console.log({ url })

    if (method === 'POST' || method === 'PUT') {
      if (query instanceof FormData) {
        options['body'] = query
        options['headers']['Content-Type'] = 'multipart/form-data'
      } else {
        options['body'] = JSON.stringify(normalize(query || {}, 'snake'))
        options['headers']['Content-Type'] = 'application/json'
      }
    } else {
      options['headers']['Content-Type'] = 'application/json'
    }

    //@TODO@TMP@CHANGETHIS
    if (url.includes('assets.json')) {
      url = url.replace('api/', '')

      let headers = {
        Accept: 'application/json',
      }

      options['headers'] = headers
    }

    try {
      const res = await fetch(url, options)

      if (!res.ok) {
        console.log('network-failed', options, url)
        const errorsText = await res.text()
        let errors = getJsonFromText(errorsText)
        // console.error({ errors })
        if (res.status === 401) {
          appStorage.set('accessToken', null)
          appStorage.set('userInfo', null)
          appStorage.set('city_id', null)
          // authToken = null
          // if (navigation.navigationRef?.current) {
          //   navigation.navigate('AUTH_LOGIN')
          // }

          throw new Error('401')
        }

        // if (errors.errors) {
        //   throw normalize(errors.errors, 'camel')
        // } else {
        //   throw errors
        // }

        if (errors !== false) {
          throw normalize({ ...errors }, 'camel')
        } else {
          throw new Error('invalid response or network issue')
        }
      }

      const appUpdate = res.headers.get('x-app-update')
      const apiVersion = res.headers.get('x-api-version')

      // console.log({ version: Constants.manifest.version, appUpdate, apiVersion })

      if (navigation.navigationRef?.current && appUpdate === '1' && apiVersion !== Constants.manifest.version) {
        navigation.navigate('FORCE_UPDATE')
      }

      const json = await res.json()

      // console.log('ApiResponse-------->', json);

      if (args.dataPath) {
        return get(json, args.dataPath)
      }

      if (args.paginate) {
        return normalize(json, 'camel')
      }

      const data = json['data'] ? json.data : json

      if (data.access_token) {
        authToken = data.access_token
        appStorage.set('accessToken', data.access_token)
      }

      return normalize(data, 'camel')
    } catch (e) {
      console.log({ errorCachted: e })
      // console.log({ typeof: typeof e.message }, { message: e.message })
      throw e
      // return []
    }
  }
}

const restApiEndpoints = {
  getActivityList: {
    endpoint: ({ pageParam, queryKey }) => {
      let url = `/events?`

      // if (queryKey[1]) {
      //   url += new URLSearchParams(queryKey[1]).toString()
      // }

      if (queryKey[1]) {
        url += `featured=true&`
      }

      if (queryKey[2]) {
        url += `formatType=${queryKey[2]}&`
      }
      url += `page=${pageParam || 1}`

      return url
    },
    paginate: true,
  },

  getTrendyActivityList: {
    endpoint: ({ pageParam, queryKey }) => {
      let url = `/events/trendy?page=${pageParam || 1}`
      return url
    },
    paginate: true,
  },

  getPopularActivityList: {
    endpoint: ({ pageParam, queryKey }) => {
      let url = `/events/popular?page=${pageParam || 1}`
      return url
    },
    paginate: true,
  },

  getCuratedActivityList: {
    endpoint: ({ pageParam, queryKey }) => {
      let url = `/events/curated?page=${pageParam || 1}`
      return url
    },
    paginate: true,
  },
  searchActivity: {
    endpoint: ({ pageParam, queryKey }) => {
      let url = `/events?`
      if (queryKey[1]) {
        url += `searchq=${queryKey[1]}&`
      }

      if (queryKey[2]) {
        url += `formatType=${queryKey[2]}&`
      }

      url += `page=${pageParam || 1}`

      return url
    },
    paginate: true,
  },
  partnerSearch: {
    endpoint: ({ pageParam, queryKey }) => {
      let url = `/brands?page=${pageParam || 1}`
      if (queryKey[1]) {
        url += `&searchq=${queryKey[1]}&`
      }
      return url
    },
    paginate: true,
  },
  getPartnerDetails: {
    endpoint: ({ queryKey }) => `/brands/${queryKey[1]}?with=events`,
  },
  createPartnerEnquires: {
    endpoint: `/brands/enquire`,
    type: 'POST',
  },
  getActivityDetails: {
    endpoint: ({ queryKey }) => `/events/${queryKey[1]}`,
  },
  getActivityReviews: {
    endpoint: ({ queryKey }) => `/events/${queryKey[1]}/reviews`,
  },
  createActivityReview: {
    endpoint: ({ activityId }) => `/events/${activityId}/reviews`,
    type: 'POST',
  },
  getRelatedActivities: {
    endpoint: ({ queryKey }) => `/events/${queryKey[1]}/related`,
  },

  getFeaturedActivityList: {
    endpoint: `/events?featured=true`,
  },
  getActivityTags: {
    endpoint: `/event-formats`,
    // endpoint: ({ pageParam }) => `/event-formats/?page=${pageParam || 1}`,
    // paginate: true,
  },
  getTagActivityList: {
    endpoint: ({ queryKey }) => `/event-formats/${queryKey[1]}?with=events`,
    dataPath: 'data.events',
  },
  getBookingsHistory: {
    endpoint: ({ pageParam }) => `/bookings/history?page=${pageParam || 1}`,
    paginate: true,
  },
  getBookingsUpcoming: {
    endpoint: ({ pageParam }) => `/bookings/upcoming?page=${pageParam || 1}`,
    paginate: true,
  },
  cancelBooking: {
    endpoint: `/bookings/cancel`,
    type: 'POST',
  },
  signup: {
    endpoint: '/signup',
    type: 'POST',
  },
  login: {
    endpoint: '/login',
    type: 'POST',
    modal: [],
  },
  resetPassword: {
    endpoint: '/forgot-password',
    type: 'POST',
    modal: [],
  },
  resetPasswordToken: {
    endpoint: '/reset-password',
    type: 'POST',
    modal: [],
  },

  updateUserIntersets: {
    endpoint: '/me/format-types',
    type: 'PUT',
  },
  calenderEvents: {
    endpoint: ({ queryKey }) => {
      let url = `/calendar/events?startDate=${queryKey[1]}&endDate=${queryKey[2]}`
      return queryKey[3] ? `${url}&formatType=${queryKey[3]}` : url
    },
  },
  bookActivity: {
    endpoint: '/bookings',
    type: 'POST',
  },
  bookActivityDetails: {
    endpoint: ({ queryKey }) => `/bookings/${queryKey[1]}`,
    type: 'GET',
  },
  stripePaymentIntent: {
    endpoint: `/stripe/payment-intent`,
    type: 'POST',
  },
  stripePaymentMethodList: {
    endpoint: `/stripe/payment-methods`,
    type: 'GET',
    modal: [],
  },
  getCountryStates: {
    endpoint: `/misc/states`,
  },

  emailCheck: {
    endpoint: `/email-check`,
    type: 'POST',
  },
  accountCheck: {
    endpoint: `/account-check`,
    type: 'POST',
  },
  getPosts: {
    endpoint: ({ pageParam }) => `/posts?page=${pageParam || 1}`,
    paginate: true,
  },
  getPostDetails: {
    endpoint: ({ queryKey }) => `/posts/${queryKey[1]}`,
  },
  postLikeUnlike: {
    endpoint: ({ id }) => `/posts/${id}/like-unlike`,
    type: 'POST',
  },
  userProfileUpdate: {
    endpoint: `/me/profile`,
    type: 'PUT',
  },
  userPhotoUpload: {
    endpoint: `/me/photo`,
    type: 'POST',
  },
  userPhotoRemove: {
    endpoint: `/me/photo`,
    type: 'DELETE',
  },

  getBatchedActivityList: {
    endpoint: `/events/batched`,
  },

  getActivityDates: {
    endpoint: ({ pageParam, queryKey }) => `/partner/schedules`,
    paginate: true,
  },
  getSessionDetails: {
    endpoint: ({ queryKey }) => `/partner/schedules/${queryKey[1]}`,
  },
  otp: {
    endpoint: `/partner/otp/send`,
    type: 'POST',
  },
  otpResend: {
    endpoint: `/partner/otp/resend`,
    type: 'POST',
  },
  otpVerify: {
    endpoint: `/partner/otp/verify`,
    type: 'POST',
  },
  userInfo: {
    endpoint: '/partner/me',
    type: 'GET',
  },
  getSessionComments: {
    endpoint: ({ pageParam, queryKey }) => {
      let url = `/partner/schedules/${queryKey[1]}/comments?page=${pageParam || 1}`
      return url
    },
    paginate: true,
  },
  addComment: {
    endpoint: ({ id }) => {
      let url = `/partner/schedules/${id}/comments`
      return url
    },
    type: 'POST',
  },
  attendance: {
    endpoint: ({ id }) => {
      let url = `/partner/bookings/${id}/update/attendance`
      return url
    },
    type: 'PUT',
  },
  cancelBooking: {
    endpoint: ({ id }) => {
      let url = `/partner/bookings/${id}/cancel`
      return url
    },
    type: 'PUT',
  },
  getValidCards: {
    endpoint: ({}) => {
      const localId = i18n.language
      const url = `/partner/${localId}/assets.json`
      return url
    },
    type: 'GET',
  },
}

export default mapValues(restApiEndpoints, (e) => executeHttp(e))

import React, { useState, useEffect } from 'react'
import * as Linking from 'expo-linking'
// import dynamicLinks from '@react-native-firebase/dynamic-links'
import routes from './routes'
// import env from '@app/../env.json'

// const useMount = func => useEffect(() => func(), []);

// const useInitialURL = () => {
//   const [url, setUrl] = useState(null);
//   const [processing, setProcessing] = useState(true);

//   useMount(() => {
//     const getUrlAsync = async () => {
//       // Get the deep link used to open the app
//       const initialUrl = await Linking.getInitialURL();

//       // The setTimeout is just for testing purpose
//       setTimeout(() => {
//         setUrl(initialUrl);
//         setProcessing(false);
//       }, 1000);
//     };

//     getUrlAsync();
//   });

//   return { url, processing };
// };

const routesConfig = {
  screens: {
    initialRouteName: routes.LANDING_SCREEN,
    // [routes.AUTH_LOGIN] : {
    //   path: '/login',
    //   exact: true,
    // },
    [routes.LOGIN_SCREEN]: {
      // path: '/signup',
      path: '/login',
      exact: true,
    },
    [routes.LOGIN_WITH_MOBILE]: {
      // path: '/reset',
      path: '/login-mobile',
      exact: true,
    },
    [routes.CHAT]: {
      // path: '/reset',
      path: '/chat',
      exact: true,
    },
  },
}

let prefixes = [
  Linking.createURL('/'),
  'http://localhost',
  'http://localhost:19006',
  'http://localhost:19006/',
  // ...env.LINK_PREFIXES,
  // env.APP_DOMAIN,
  // env.BRANCH_DOMAIN
]

const linkingConfig = {
  prefixes: prefixes,
  config: routesConfig,

  /*

  // Custom function to get the URL which was used to open the app
  async getInitialURL() {
    try {
      // First, you may want to do the default deep link handling
      // Check if app was opened from a deep link
      const url = await Linking.getInitialURL()

      if (url != null) {
        return url
      }

      const firebaseLink = await dynamicLinks().getInitialLink()

      console.log('\n\n==========dynamicLinks().getInitialLink==============')
      console.log('==firebaseLink--->', firebaseLink)

      if (firebaseLink && firebaseLink.url) {
        console.log('==firebaseLink.url--->', firebaseLink.url)
        return firebaseLink.url
      }

      // Next, you would need to get the initial URL from your third-party integration
      // It depends on the third-party SDK you use
      // For example, to get to get the initial URL for branch.io:
      // const params = branch.getFirstReferringParams()

      // console.log('getInitialURL--->Branch--->Params---->', params)
      // const branchParams = params || {}
      // return branchParams['~referring_link'] || branchParams?.$canonical_url
      // return parseBranchParams(params)
    } catch (err) {
      console.log('linkingConfig-->getInitialURL---->err', err)
    }
  },

  // Custom function to subscribe to incoming links
  subscribe(listener) {
    try {
      // First, you may want to do the default deep link handling
      const onReceiveURL = ({ url }) => {
        console.log('onReceiveURL----url----->', url)
        listener(url)
      }

      // Listen to incoming links from deep linking
      Linking.addEventListener('url', onReceiveURL)

      const unsubscribeFirebaseLinks = dynamicLinks().onLink((firebaseLink) => {
        console.log('\n\n==========dynamicLinks().onLink==============')
        console.log('==firebaseLink--->', firebaseLink)
        console.log('==firebaseLink.url--->', firebaseLink.url)

        listener(firebaseLink.url)
      })
      return () => {
        // Clean up the event listeners
        Linking.removeEventListener('url', onReceiveURL)
        // unsubscribeBranchEvents()
        unsubscribeFirebaseLinks()
      }
    } catch (err) {
      console.log('linkingConfig-->subscribe---->err', err)
      return () => {}
    }
  },
*/
}

export default linkingConfig

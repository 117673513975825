import _ from 'lodash'
import React from 'react'
import { StyleSheet, View, TextInput, Image, Text, Platform } from 'react-native'
import Svg, { Path } from 'react-native-svg'
import { scale, fScale } from '@app/atoms/scale-view'
import Icons from './icons.atom'
import Select from 'react-select'
import './reactSelect.css'

function SvgComponent(props) {
  return (
    <Svg
      width={scale(20)}
      height={scale(14)}
      viewBox="0 0 10 7"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}>
      <Path d="M1 1.692l4.038 4.039 4.039-4.039" stroke="#2A888C" strokeWidth={1.61538} strokeLinecap="round" />
    </Svg>
  )
}

function PhoneField({
  label,
  help,
  mode,
  icon,
  error,
  containerStyle,
  inputStyle,
  inputBackground = '#fff',
  onChangeText,
  value,
  ...inputProps
}) {
  const [phoneIsdCode, setPhoneIsdCode] = React.useState('+34')
  const [phoneText, setPhoneText] = React.useState('')

  const leftIcon = icon ? <Text style={styles.imageStyle}>{icon}</Text> : null

  const errorText = _.isArray(error) ? error.join('. ') : _.isString(error) ? error : null
  const errorStyle = errorText ? styles.error : {}

  const placeholder = {
    label: 'Select',
    value: '+34',
    color: '#9EA0A4',
    inputLabel: '🇪🇸',
  }
  const [selectedOption, setSelectedOption] = React.useState({ label: '🇪🇸', value: '+34', inputLabel: '🇪🇸 ' })

  const options = [
    { label: '🇪🇸', value: '+34', inputLabel: '🇪🇸' },
    { label: '🇦🇩', value: '+376', inputLabel: '🇦🇩' },
  ]

  React.useEffect(() => {
    onChangeText(phoneIsdCode + phoneText)
  }, [phoneIsdCode, phoneText])

  return (
    <View style={[containerStyle, { paddingBottom: scale(15) }]}>
      {label && (
        <Text>
          <Text style={styles.label}>{label}</Text> <Text style={styles.helpText}> {help}</Text>
        </Text>
      )}
      <View style={[styles.container]}>
        <View
          style={[
            styles.sectionStyle,
            {
              backgroundColor: inputBackground,
            },
            errorStyle,
          ]}>
          <View style={{ marginRight: scale(5) }}>{leftIcon}</View>
          <Select
            className="css-1s2u09g-control"
            style={{
              ...pickerSelectStyles.inputIOS,
              inputAndroid: { padding: 10 },
              inputIOS: { padding: 10 },
              iconContainer: pickerSelectStyles.iconContainer,
            }}
            value={selectedOption}
            onChange={(value) => {
              setSelectedOption(value)
              setPhoneIsdCode(value.value)
            }}
            options={options}
            defaultValue={{ label: '🇪🇸 Spain +34', value: '+34', inputLabel: '🇪🇸 ' }}
          />
          <Text style={{ fontSize: fScale(20) }}>{phoneIsdCode}</Text>
          <TextInput
            value={phoneText}
            style={[inputStyle, { flex: 1, marginLeft: scale(5), fontSize: fScale(20), width: scale(100) }]}
            onChangeText={(text) => {
              setPhoneText(text.replace(/[^0-9]/g, ''))
            }}
            maxLength={10}
            {...inputProps}
          />
          {errorText ? (
            <View style={{ marginRight: 5 }}>
              <Icons.InputAlertIcon />
            </View>
          ) : null}
        </View>
      </View>
      {errorText && (
        <View style={styles.errorContainer}>
          {/* <Image source={iconImages['alert']} style={styles.errorImage} /> */}
          <Icons.InputAlertIcon />
          <Text style={styles.errorText}>{errorText}</Text>
        </View>
      )}
    </View>
  )
}

const pickerSelectStyles = StyleSheet.create({
  inputIOS: {
    fontSize: fScale(20),
    paddingVertical: scale(12),
    borderWidth: 1,
    borderColor: 'gray',
    borderRadius: 4,
    color: 'black',
    marginLeft: 10,
    paddingRight: scale(30), // to ensure the text is never behind the icon
  },

  iconContainer: {
    top: Platform.OS === 'ios' ? scale(18) : scale(15),
    left: Platform.OS === 'ios' ? scale(45) : scale(50),
    width: scale(50),
    height: scale(50),
    justifyContent: 'center',
    alignItems: 'center',
  },
})

const styles = StyleSheet.create({
  container: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: 5,
  },
  label: {
    color: '#4F4F4F',
    fontFamily: 'roboto-medium',
    fontSize: fScale(30),
    // lineHeight: fScale(18.75),
  },
  error: {
    backgroundColor: 'rgba(255, 172, 51, 0.2)',
    // borderRadius: 1,
    borderColor: '#EC9E2D',
  },
  helpText: {
    color: '#989898',
    fontFamily: 'roboto-regular',
    fontSize: fScale(14),
    lineHeight: fScale(16.41),
  },
  sectionStyle: {
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: '#fff',
    borderWidth: 1,
    borderColor: 'rgba(0,0,0,0)',
    height: scale(60),
    width: '100%',
    borderRadius: 10,
  },
  imageStyle: {
    justifyContent: 'center',
    alignItems: 'center',
    marginLeft: scale(10),
    marginRight: scale(5),
    height: scale(25),
    width: scale(25),
  },
  errorContainer: {
    flex: 1,
    flexDirection: 'row',
    flexWrap: 'wrap',
    alignContent: 'center',
  },
  errorImage: {
    resizeMode: 'contain',
    position: 'absolute',
  },
  errorText: {
    color: '#EC9E2D',
    flexShrink: 1,
    flexWrap: 'wrap',
    marginLeft: scale(2),
    fontFamily: 'roboto-regular',
    fontSize: fScale(16),
    // lineHeight: 18.41,
  },
})

export default PhoneField

//
// Typos are annoyingly frustrating issues for navigation.  You can mistype an enum too,
// but at least you have the option of choosing the correct label.
//
const BLANK = ''

const routes = {
  BLANK,
  NOT_FOUND: 'not-found',
  FORCE_UPDATE: 'FORCE_UPDATE',
  // auth routes
  AUTH_LANDING: 'AUTH_LANDING',
  AUTH_LOGIN: 'AUTH_LOGIN',
  AUTH_SIGNUP: 'AUTH_SIGNUP',
  AUTH_INFO_CONFIRM: 'AUTH_INFO_CONFIRM',
  AUTH_RESET_FORM: 'AUTH_RESET_FORM',
  AUTH_RESET_CONFIRM: 'AUTH_RESET_CONFIRM',
  AUTH_RESET_TOKEN: 'AUTH_RESET_TOKEN',
  AUTH_RESET_TOKEN_SUCESSS: 'AUTH_RESET_TOKEN_SUCESSS',

  LANDING_SCREEN: 'welcome',
  LOGIN_SCREEN: 'login',
  LOGIN_WITH_MOBILE: 'login-mobile',
  OTP_SCREEN: 'otp',

  HOME_SCREEN: 'home',
  SESSION_DEATILS: 'session-details',

  CHAT: 'chat',
  VALID_CARDS: 'valid-cards',
}

export default routes

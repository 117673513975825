import React, { useState } from 'react'
import { View, Text, Image, StyleSheet, TouchableOpacity, Pressable, SafeAreaView, Modal, Linking } from 'react-native'
import { useSafeAreaInsets } from 'react-native-safe-area-context'
import { AntDesign } from '@expo/vector-icons'

import { useTranslation } from 'react-i18next'
import Icons from '@app/atoms/icons.atom'
import appStorage from '@app/services/app-storage.service'
import { useAuth } from '@app/hooks/use-auth'
import SecondaryButton from '@app/atoms/support-button.atom'

import { scale, fScale } from '@app/atoms/scale-view'
import SectionBackButton from './section-back-button.molecule'
import gs from '@app/atoms/gs.atom'
import Colors from '@app/atoms/colors'
import { useRoute } from '@react-navigation/native'
import * as navigationRef from '@app/navigation/root-ref'
import routes from '@app/navigation/routes'

function AppHeader({ navigation, options, showMenu = false, showBackButton = false, showRightMenu = false, ...props }) {
  // const route = useRoute();
  const { t } = useTranslation()
  const auth = useAuth()
  const insets = useSafeAreaInsets()
  const [showCallModal, setCallModal] = useState(false)

  const route = navigationRef.getCurrentRoute()

  return (
    <View style={[styles.headerStyle, { paddingTop: Math.min(insets.top, scale(12)) }]}>
      <View style={styles.container}>
        <View style={{ minWidth: scale(86) }}>
          {showMenu ? (
            <TouchableOpacity style={styles.menu} onPress={() => navigation.openDrawer()}>
              <Text style={styles.menuLabel}>{t('Menu')}</Text>
            </TouchableOpacity>
          ) : showBackButton ? (
            <SectionBackButton varient={true} containerStyle={{}} />
          ) : (
            <View style={{ width: scale(70) }}></View>
          )}
        </View>
        <View>
          {showRightMenu ? (
            <>
              <TouchableOpacity style={styles.menu} onPress={() => setCallModal(true)}>
                <Text style={styles.menuLabel}>{t('Medium')}</Text>
              </TouchableOpacity>
              {/*
              <TouchableOpacity style={styles.menu} onPress={() => navigation.navigate(routes.CHAT)}>
                <Text style={styles.menuLabel}>{t('common:Chat')}</Text>
              </TouchableOpacity>
              */}
            </>
          ) : (
            <View style={{ width: scale(70) }}></View>
          )}
        </View>
        <View>
          <TouchableOpacity
            style={styles.menu}
            onPress={() => {
              auth.logout()
            }}>
            <Text style={styles.menuLabel}>{t('Logout')}</Text>
          </TouchableOpacity>
        </View>

        {showCallModal && (
          <SafeAreaView style={{ flex: 1, backgroundColor: Colors.BACKGROUND_COLORS.C50 }}>
            <Modal
              animationType="slide"
              transparent={true}
              visible={showCallModal}
              onRequestClose={() => {
                // Alert.alert('Modal has been closed.')
                setCallModal(false)
              }}>
              <View style={modelStyles.centeredView}>
                <View style={modelStyles.modalView}>
                  <View style={{ flex: '1', flexDirection: 'row', justifyContent: 'space-between' }}>
                    <Text style={[modelStyles.canelText, { color: '#2A888C' }]}>{t('Medium')}</Text>
                    <TouchableOpacity onPress={() => setCallModal(false)} style={{ alignItems: 'flex-end' }}>
                      <AntDesign name="closecircle" size={32} color="#2A888C" />
                    </TouchableOpacity>
                  </View>
                  <View style={{ flexDirection: 'row', justifyContent: 'space-evenly', marginTop: scale(10) }}>
                    <SecondaryButton
                      containerStyle={{}}
                      onPress={() => {
                        Linking.openURL(`tel:+34634762055`)
                      }}
                      icon={<Icons.InputPhoneIcon width={scale(24)} height={scale(24)} strokeWidth="2.5" />}>
                      <Text style={[modelStyles.buttonText]}>{t('Call')}</Text>
                    </SecondaryButton>
                    <SecondaryButton
                      containerStyle={{}}
                      onPress={() => {
                        Linking.openURL(`whatsapp://send?text=hello&phone=+34634762055}`)
                      }}
                      icon={<Icons.CommunityWhatsappIcon width={fScale(24)} height={fScale(24)} fill="#69AC6E" />}>
                      <Text style={[modelStyles.buttonText, { color: '#69AC6E' }]}>Whatsapp</Text>
                    </SecondaryButton>
                  </View>
                  <View style={{ flexDirection: 'row', justifyContent: 'center' }}>
                    <SecondaryButton
                      containerStyle={{}}
                      onPress={() => {
                        Linking.openURL(`https://vermutapp.zendesk.com/hc/es`)
                      }}
                      icon={<Icons.HelpCenter width={fScale(24)} height={fScale(24)} fill="#69AC6E" />}>
                      <Text style={[modelStyles.buttonText]}>{t('Help Center')}</Text>
                    </SecondaryButton>
                  </View>
                </View>
              </View>
            </Modal>
          </SafeAreaView>
        )}
      </View>
    </View>
  )
}

const styles = StyleSheet.create({
  headerStyle: {
    height: scale(72),
    borderBottomWidth: 1.5,
    borderBottomColor: Colors.TEXT_COLORS.C500,
    width: '100%',
  },
  container: {
    // backgroundColor: "red",
    flex: 1,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginHorizontal: scale(20),
  },
  menu: {
    alignItems: 'center',
    paddingHorizontal: scale(13),
    paddingVertical: scale(10),
    justifyContent: 'center',
    backgroundColor: Colors.SECONDARY_COLORS.C50,
    borderRadius: 24,
  },
  menuLabel: {
    ...gs.textBody20,
    fontFamily: 'Roboto',
    color: Colors.TEXT_COLORS.C800,
    fontSize: scale(20),
    lineHeight: fScale(23.44),
    fontWeight: '500',
  },
})

const modelStyles = StyleSheet.create({
  centeredView: {
    flex: 1,
    justifyContent: 'center',
    backgroundColor: 'rgba(86, 86, 86, 0.9)',
  },
  modalView: {
    margin: scale(40),
    backgroundColor: '#FFFFFF',
    borderRadius: scale(30),
    paddingVertical: scale(25),
    paddingHorizontal: scale(20),
    shadowColor: '#000',
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.25,
    shadowRadius: 2,
    elevation: 2,
  },
  button: {
    borderRadius: scale(20),
    padding: scale(18),
  },
  buttonNo: {
    flex: 1,
    marginRight: scale(28),
    borderWidth: 1.5,
    borderColor: '#6F6F6F',
    color: '#3D3D3D',
  },
  buttonNoText: {
    ...gs.h3,
    fontWeight: 'bold',
    textAlign: 'center',
    textTransform: 'uppercase',
  },
  buttonYes: {
    flex: 1,
    backgroundColor: Colors.SECONDARY_COLORS.C400,
    borderWidth: 1.5,
    borderColor: Colors.SECONDARY_COLORS.C400,
  },
  buttonYesText: {
    ...gs.h3,
    color: 'white',
    fontWeight: 'bold',
    textAlign: 'center',
    textTransform: 'uppercase',
  },
  canelText: {
    ...gs.subTitle500,
    color: Colors.PRIMARY_COLORS.C700,
    marginBottom: scale(9),
    fontSize: scale(24),
  },
  cancelName: {
    ...gs.subTitle400,
    color: Colors.TEXT_COLORS.C800,
    marginBottom: scale(20),
    fontSize: scale(24),
  },
  activityTitle: {
    ...gs.subTitle400,
    marginBottom: scale(19),
  },

  confirmText: {
    ...gs.subTitle500,
    color: Colors.TEXT_COLORS.C800,
    marginBottom: scale(20),
    fontSize: scale(30),
  },
  buttonText: {
    color: Colors.TEXT_COLORS.C800,
    fontSize: scale(20),
    lineHeight: scale(23),
    fontStyle: 'normal',
    marginLeft: scale(2),
  },
})
export default AppHeader

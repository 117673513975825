/**
 * If you are not familiar with React Navigation, refer to the "Fundamentals" guide:
 * https://reactnavigation.org/docs/getting-started
 *
 */
import { createNativeStackNavigator } from '@react-navigation/native-stack'
import * as React from 'react'
import { NavigationContainer } from '@react-navigation/native'
import * as RootNavigation from './root-ref'
import ModalScreen from '@app/screens/ModalScreen'
import NotFoundScreen from '@app/screens/NotFoundScreen'
import BottomTabNavigator from './BottomTabNavigator'
import ForceUpdateScreen from '@app/screens/ForceUpdateScreen'
import { useAuth } from '@app/hooks/use-auth'
import AuthNavigation from '@app/navigation/auth-navigation'
import MainHomeNavigation from '@app/navigation/main-home-navigation'
import linkingConfig from './LinkingConfiguration'
import { ActivityIndicator } from 'react-native-paper'

/**
 * A root stack navigator is often used for displaying modals on top of all other content.
 * https://reactnavigation.org/docs/modal
 */
const Stack = createNativeStackNavigator()

export default function RootNavigator(props) {
  const auth = useAuth()

  return !auth.user ? <AuthNavigation {...props} /> : <MainHomeNavigation {...props} />
}

import React from 'react'
import { StyleSheet } from 'react-native'
import Colors from './colors'

import { scale, fScale } from '@app/atoms/scale-view'

const globalStyles = StyleSheet.create({
  /** debugStyles */
  red: {
    backgroundColor: 'red',
  },
  green: {
    backgroundColor: 'green',
  },
  yellow: {
    backgroundColor: 'yellow',
  },
  border: {
    borderColor: 'red',
    borderWidth: 2,
  },
  /** debugStyles end */
  container: {},
  ph: {
    paddingHorizontal: scale(20),
  },
  h1: {
    paddingTop: scale(10),
    fontFamily: 'amiri-bold',
    fontSize: fScale(32),
    lineHeight: fScale(32),
    color: Colors.TEXT_COLORS.C800,
  },

  h2: {
    fontFamily: 'roboto-bold',
    fontSize: fScale(24),
    lineHeight: fScale(28.13),
    letterSpacing: 0.19,
    color: Colors.TEXT_COLORS.C800,
  },
  h3: {
    fontFamily: 'roboto-regular',
    fontSize: fScale(20),
    lineHeight: fScale(23.44),
    color: Colors.TEXT_COLORS.C800,
  },
  textLink: {
    fontFamily: 'roboto-medium',
    fontSize: fScale(16),
    lineHeight: fScale(18.75),
    color: Colors.SECONDARY_COLORS.C400,
  },
  textLinkUnderline: {
    fontFamily: 'roboto-medium',
    fontSize: fScale(16),
    lineHeight: fScale(18.75),
    color: Colors.SECONDARY_COLORS.C400,
    textDecorationLine: 'underline',
  },
  cardTitle: {
    fontFamily: 'roboto-bold',
    fontSize: fScale(30),
    lineHeight: fScale(35.16),
    color: Colors.TEXT_COLORS.C800,
  },
  text: {
    fontFamily: 'roboto-regular',
    fontSize: fScale(16),
    lineHeight: fScale(18.75),
    color: Colors.TEXT_COLORS.C800,
  },
  textBody24: {
    fontFamily: 'roboto-regular',
    fontSize: fScale(24),
    lineHeight: fScale(28.13),
    color: Colors.TEXT_COLORS.C800,
  },
  textBody20: {
    fontFamily: 'roboto-regular',
    fontSize: fScale(20),
    lineHeight: fScale(25.6),
    color: '#6F6F6F',
    color: Colors.TEXT_COLORS.C600,
  },
  textBody12: {
    fontFamily: 'roboto-regular',
    fontSize: fScale(12),
    lineHeight: fScale(14.06),
    color: Colors.TEXT_COLORS.C600,
  },
  textBody14: {
    fontFamily: 'roboto-regular',
    fontSize: fScale(14),
    lineHeight: fScale(16.41),
    color: '#000',
  },
  textTagsBullets: {
    fontFamily: 'roboto-regular',
    fontSize: fScale(14),
    lineHeight: fScale(16.41),
    color: Colors.TEXT_COLORS.C800,
  },
  textCenter: {
    textAlign: 'center',
  },
  errorText: {
    fontFamily: 'roboto-regular',
    fontSize: fScale(16),
    lineHeight: fScale(18.75),
    color: Colors.PRIMARY_COLORS.C700,
  },
  shadowStyleMain: {
    shadowColor: '#000',
    shadowOffset: {
      width: 2,
      height: 4,
    },
    shadowOpacity: 0.15,
    shadowRadius: 8,
    elevation: 4,
  },
  floatingLinkBottom: {
    position: 'absolute',
    bottom: scale(20),
    right: scale(20),
    textAlign: 'right',
    color: Colors.SECONDARY_COLORS.C400,
    fontFamily: 'roboto-medium',
    fontSize: fScale(13),
    lineHeight: fScale(15),
    paddingTop: scale(10),
    textTransform: 'uppercase',
  },
  buySubscriptionText: {
    fontFamily: 'roboto-regular',
    fontSize: fScale(30),
    lineHeight: fScale(35.16),
    color: Colors.PRIMARY_COLORS.C700,
    fontWeight: '500',
  },
})

export default globalStyles

import _ from 'lodash'
import React, { useState, useEffect, useContext, createContext } from 'react'
import { useQueryClient, useMutation } from 'react-query'
import { useAuth } from '@app/hooks/use-auth'
import Constants from 'expo-constants'
import { StreamChat } from 'stream-chat'
import { SafeAreaProvider, useSafeAreaInsets } from 'react-native-safe-area-context'
import { STREAMCHAT_ACCESS_KEY } from '@env'

console.log('STREAMCHAT_ACCESS_KEY----->', STREAMCHAT_ACCESS_KEY)

const accessKey = STREAMCHAT_ACCESS_KEY
const chatContext = createContext()
const chatClient = StreamChat.getInstance(accessKey)

// LogBox.ignoreAllLogs(true);
/**
 * Start playing with streami18n instance here:
 * Please refer to description of this PR for details: https://github.com/GetStream/stream-chat-react-native/pull/150
 */
// const streami18n = new Streami18n({
//   language: 'en',
// });

export function ChatProvider({ children }) {
  const chatInfo = useInitChat()
  const { bottom } = useSafeAreaInsets()
  return <chatContext.Provider value={chatInfo}>{children}</chatContext.Provider>
}

export const useChat = () => {
  return useContext(chatContext)
}

function useInitChat() {
  const queryClient = useQueryClient()
  const auth = useAuth()
  const [user, setUser] = useState({})

  const [channel, setChannel] = useState()
  const [clientReady, setClientReady] = useState(false)
  const [thread, setThread] = useState()

  useEffect(() => {
    if (!auth.accessToken) {
      //should be disconnected
      return
    }

    const setupClient = async () => {
      console.log('\n\n-------->setupClient---')
      console.log('auth->user->', auth?.user)
      const user = {
        id: `${auth?.user?.chatUserId}`,
        // id : 'hidden-cake-3'
      }

      // console.log('\n\n-------->user--->', user);

      const userToken = auth?.user?.chatToken

      // const userToken = chatClient.devToken(auth?.user?.firstName);

      //       const userToken =
      //   'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VyX2lkIjoicm9uIn0.eRVjxLvd4aqCEHY_JRa97g6k7WpHEhxL7Z4K4yTot1c';
      // const user = {
      //   id: 'ron',
      // };

      //@TODO@CHAT - sometimes multiple calls happen to connectUser
      // if(!clientReady){
      try {
        //@TODO@CHAT : review - consider using async in connect user for token to handle automatic refresh token handling
        const chatUser = await chatClient.connectUser(user, userToken)
        setClientReady(true)
      } catch (err) {
        console.log('\n\n-setupClient-->err----->', err)
      }
      // }
    }

    setupClient()

    // return () => {
    //   //@TODO@CHAT : not working as expected - may be cause
    //   //chat provider is wrapped around ChatStack
    //   console.log('disconnecting--chat-->', )

    //   const disconnectChat = async () => {
    //     setClientReady(false);
    //     await chatClient?.disconnectUser();
    //   }

    //   disconnectChat();
    // }
  }, [auth.accessToken])

  return {
    user,
    chatClient,
    clientReady,
    channel,
    setChannel,
    thread,
    setThread,
  }
}

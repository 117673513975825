import { doFetch, processApiRes, processApiErrors } from '@app/services/fetch'

export const sendOTP = async (reqParams) => {
  try {
    const url = `/otp`
    const res = await doFetch(url, {
      method: 'POST',
      body: JSON.stringify(reqParams),
    }).ready
    const resData = await processApiRes(res)
    return resData
  } catch (err) {
    const errors = await processApiErrors(err)
    throw errors
  }
}

export const verifyOTP = async (reqParams) => {
  try {
    const url = `/verify`
    const res = await doFetch(url, {
      method: 'POST',
      body: JSON.stringify(reqParams),
    }).ready
    const resData = await processApiRes(res)
    return resData
  } catch (err) {
    const errors = await processApiErrors(err)
    throw errors
  }
}

import React from 'react'
import { useFocusEffect } from '@react-navigation/native'

const useRefetchOnFocus = (refetch = () => {}, canRefetch = true) => {
  const [isScreenFocused, setIsScreenFocused] = React.useState(false)
  useFocusEffect(() => {
    setIsScreenFocused(true) // when i focus the screen
    return () => setIsScreenFocused(false) // when i quit the screen
  })

  /* the screen still always active in cache so we need to check that the screen is focused in a use effect
  to dispatch the refetch only one time to avoid the infinity loop*/
  React.useEffect(() => {
    if (isScreenFocused && canRefetch) {
      refetch()
    }
  }, [canRefetch, isScreenFocused, refetch])
}

export default useRefetchOnFocus

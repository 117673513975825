import React from 'react'
import { useTranslation } from 'react-i18next'
import { StyleSheet, Text, View, Image } from 'react-native'
import dayjs from 'dayjs'
import gs from '@app/atoms/gs.atom'
import Colors from '@app/atoms/colors'
import ActivityStatusInfo from './activity-status-info.molecule'
import { format } from '@app/atoms/date'
import { scale, fScale } from '@app/atoms/scale-view'
import Icons from '@app/atoms/icons.atom'

const daysInWeek = ['Sun', 'Mon', 'Tues', 'Wed', 'Thur', 'Fri', 'Sat']
const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']

function ActivityDate(props) {
  const { containerStyle, sessionDate, changeDate = false } = props

  const { t } = useTranslation()
  const dayName = dayjs(sessionDate.startDatetime).format('ddd')
  const dateNumber = dayjs(sessionDate.startDatetime).format('DD')
  const month = dayjs(sessionDate.startDatetime).format('MMM')
  const sessionStartTime = dayjs(sessionDate.startDatetime).format('h:mm')
  const sessionEndTime = dayjs(sessionDate.endDatetime).format('h:mm')
  let opacityStyle = { opacity: 1 }
  let backgroudStyle = { backgroundColor: '#fff' }
  let shadow = gs.shadowStyleMain

  return (
    <View style={[containerStyle, styles.container, shadow, backgroudStyle]}>
      <View style={[styles.dateInfoContainer, opacityStyle]}>
        <Text style={styles.dateWeekName}>{dayName}</Text>
        <Text style={styles.dateNumber}>{dateNumber}</Text>
        <Text style={[styles.dateWeekName]}>{month}</Text>
      </View>
      <View style={styles.activityInfoContainer}>
        <Text
          style={[
            styles.dateWeekName,
            { textAlign: 'left', textTransform: 'none', minHeight: scale(50), color: '#3D3D3D'},
          ]}>
          {sessionDate?.name}
        </Text>
        <Text
          style={[
            styles.dateWeekName,
            { textAlign: 'left', textTransform: 'none', height: scale(29), marginTop: scale(5) },
          ]}>
          {sessionStartTime} to {sessionEndTime}
        </Text>
        <View style={{ flex: 1, flexDirection: 'row', marginVertical: scale(2), height: scale(29) }}>
          <Icons.ProfileIcon width={24} height={24} stroke="#6F6F6F" />
          <Text
            style={[
              styles.dateWeekName,
              { textAlign: 'left', textTransform: 'none', marginTop: scale(3), marginLeft: scale(5) },
            ]}>
            {sessionDate.bookingsCount}/{sessionDate.maxUsers}
          </Text>
        </View>
      </View>
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    flexDirection: 'row',
    backgroundColor: '#fff',
    minHeight: scale(129),
    borderRadius: scale(20),
    marginBottom: scale(14.39),
    marginHorizontal: 20,
  },
  dateInfoContainer: {
    // paddingLeft: 22,
    // paddingRight: 19.25,
    paddingHorizontal: scale(20),
    // paddingBottom: scale(10.19),
    justifyContent: 'center',
    alignItems: 'center',
    borderRightColor: Colors.TEXT_COLORS.C500,
    borderRightWidth: 1,
  },
  dateWeekName: {
    // minWidth: scale(106),
    textTransform: 'uppercase',
    fontFamily: 'Roboto',
    fontSize: scale(18),
    color: Colors.TEXT_COLORS.C800,
    textAlign: 'center',
    fontWeight: '500',
    lineHeight: scale(21.44),
  },
  dateNumber: {
    fontFamily: 'Roboto',
    fontSize: scale(54),
    color: Colors.TEXT_COLORS.C800,
    margin: scale(0),
    padding: scale(0),
    lineHeight: scale(63.28),
  },
  activityInfoContainer: {
    paddingLeft: scale(15),
    paddingRight: scale(10),
    paddingTop: scale(12),
    justifyContent: 'space-evenly',
    flexShrink: 1,
  },
  activityInfoTiming: {
    ...gs.title500,
  },
  activityInfoPlace: {
    ...gs.textDetails400,
    marginTop: scale(11),
    marginBottom: scale(17),
    flexWrap: 'wrap-reverse',
  },
  knowMoreText: {
    ...gs.textDetails500,
    color: Colors.SECONDARY_COLORS.C400,
    alignSelf: 'flex-end',
    right: 0,
    bottom: scale(0),
  },
  activityStatusContainer: {
    // marginTop: scale(22),
  },
})

export default ActivityDate

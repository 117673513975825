import React from 'react'
import { StyleSheet, Text, Pressable, View } from 'react-native'
import PropsType from 'prop-types'
import { useInfiniteQuery } from 'react-query'
import { useNavigation } from '@react-navigation/native'
import { ActivityIndicator } from 'react-native-paper'

import globalStyles from '@app/atoms/global-style.atom'
import ActivityDate from './activity-date.molecule'
import Colors from '@app/atoms/colors'
import routes from '@app/navigation/routes'
import api from '@app/services/api.service'
import { useTranslation } from 'react-i18next'
import useRefetchOnFocus from '@app/hooks/use-refetch-on-focus'
import * as utils from '@app/utils'
import { scale } from '@app/atoms/scale-view'
import SecondaryButton from '@app/atoms/secondary-button.atom'
import { useAuth } from '@app/hooks/use-auth'
import * as Linking from 'expo-linking'

function ActivityDateSection(props) {
  const { activity, containerStyle, onLayout, guestBooking } = props
  const isAvailableDates = activity?.isAvailableDates || true
  const auth = useAuth()

  const navigation = useNavigation()
  const { t } = useTranslation()

  return (
    <View style={[containerStyle, styles.container]} onLayout={onLayout}>
      <Text
        style={[
          styles.name,
          { paddingTop: scale(7), paddingBottom: scale(7), marginBottom: scale(15), paddingLeft: scale(20) },
        ]}>
        {activity.date}
      </Text>
      {_.map(activity?.list, (item, index) => {
        const id = 11937 //11941item.id //
        return (
          <Pressable
            key={`session-date-${item.id}`}
            onPress={() => {
              navigation.navigate(routes.SESSION_DEATILS, { id: item.id })
            }}>
            <ActivityDate sessionDate={item} />
          </Pressable>
        )
      })}
    </View>
  )
}

ActivityDateSection.propTypes = {
  // containerStyle: ViewPropTypes.style,
  activity: PropsType.object.isRequired,
}

export default ActivityDateSection

const styles = StyleSheet.create({
  container: {
    // ...globalStyles.red
  },
  name: {
    fontFamily: 'Roboto',
    fontSize: scale(20),
    color: Colors.TEXT_COLORS.C800,
    fontWeight: '500',
    lineHeight: scale(23.44),
    width: '100%',
    backgroundColor: '#E0EDEF',
  },
})

import React from 'react'
import { Text, View, SafeAreaView, ScrollView, Image, StyleSheet, ImageBackground } from 'react-native'
import { useNavigation } from '@react-navigation/core'
import { StackActions } from '@react-navigation/native'
import { useTranslation } from 'react-i18next'

import routes from '@app/navigation/routes'
import Colors from '@app/atoms/colors'
import PrimaryButton from '@app/atoms/primary-button.atom'
import { scale, fScale } from '@app/atoms/scale-view'

import * as utils from '@app/utils'
import gs from '@app/atoms/gs.atom'

function LandingScreen() {
  const { t } = useTranslation()
  const navigation = useNavigation()

  return (
    <SafeAreaView style={{ flex: 1, backgroundColor: Colors.SECONDARY_COLORS.C50 }}>
      <View style={{ flex: 1, justifyContent: 'center', marginVertical: scale(200) }}>
        <Image style={styles.logoImage} source={require('@app/assets/images/vermut_logo.svg')} />

        <Text style={[styles.title, { marginTop: scale(26) }]}>Vermut Partner</Text>

        <PrimaryButton
          containerStyle={{ marginTop: scale(40), marginHorizontal: scale(20) }}
          onPress={() => {
            // utils.track('Create Account Started', {})
            navigation.dispatch(StackActions.replace(routes.LOGIN_SCREEN))
          }}>
          <Text>{t(`Start`)}</Text>
        </PrimaryButton>
      </View>
    </SafeAreaView>
  )
}

const styles = StyleSheet.create({
  scrollView: {
    // flex: 1,
    // marginTop: 27,
    marginHorizontal: 20,
    // paddingBottom: 25,
    backgroundColor: Colors.BACKGROUND_COLORS.C10,
    justifyContent: 'center',
    alignItems: 'center',
  },
  title: {
    ...gs.subTitle400,
    textAlign: 'center',
    marginHorizontal: scale(40),
    fontSize: scale(24),
  },
  logoImage: {
    width: scale(129),
    height: scale(129),
    alignSelf: 'center',
  },
})

export default LandingScreen

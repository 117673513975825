import AsyncStorage from '@react-native-async-storage/async-storage'

const APP_KEY = 'vermutapp'

class AppStorage {
  constructor() {
    this.mapping = {}
  }

  load = async () => {
    const state = await AsyncStorage.getItem(APP_KEY)
    this.mapping = JSON.parse(state || '{}')
    // console.log("loading ", { mapping: this.mapping })
  }

  get = (key) => {
    // console.log({ [key]: this.mapping[key] })
    return this.mapping[key] || null
  }

  set = (key, value) => {
    this.mapping[key] = value
    // console.log('setting', { [key]: value })
    return AsyncStorage.setItem(APP_KEY, JSON.stringify(this.mapping))
  }
}

const appStorage = new AppStorage()

export default appStorage

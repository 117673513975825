import React from 'react'
import { StyleSheet, View, Text } from 'react-native'
import { Button } from 'react-native-paper'
import { scale } from '@app/atoms/scale-view'

function FormSubmitBtn({ containerStyle, children, borderStyle, ...btnProps }) {
  return (
    <View style={[containerStyle]}>
      <Button style={[borderStyle, styles.btn]} contentStyle={styles.btnContent} {...btnProps}>
        {children}
      </Button>
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
  },
  btn: {
    borderRadius: 10,
    justifyContent: 'center',
  },
  btnContent: {
    fontFamily: 'roboto-medium',
    fontSize: scale(20),
    lineHeight: scale(23.44),
    paddingVertical: scale(5),
  },
})

export default FormSubmitBtn
